import { Component, OnInit, Input } from '@angular/core';
import { buttonStatusTypes } from '../../types/types';

@Component({
  selector: 'app-supperbutton',
  templateUrl: './supperbutton.component.html',
  styleUrls: ['./supperbutton.component.scss']
})
export class SupperbuttonComponent implements OnInit {

  private _type: string;
  jobStatus: string;
  icon: string;
  info: string;
  types ={...buttonStatusTypes};
  disable: boolean = false;


  @Input() set type(value: string){
    this.jobStatus = value;
    switch (value) {
      case(buttonStatusTypes.ACTIVE):
        this._type = 'accent';
        this.icon = 'fast_forward';
      ;break;
      case(buttonStatusTypes.DONE):
        this._type = 'disabled';
        this.icon = 'done';
        this.disable = true;
        break;
      case(buttonStatusTypes.INPROGRESS):
        this._type = 'basic';
        this.icon = 'hourglass_full';
        break;
      case(buttonStatusTypes.NOTDONEYET):
        this._type = 'basic';
        this.icon = '';
        break;
      case(buttonStatusTypes.WARN):
        this._type = 'primary';
        this.icon = '';
        break;
     case(buttonStatusTypes.HIDEN):
      this._type = 'primary';
      this.icon = '';
      break;
    }
  }

  get type(){
    return this._type;
  }

  constructor() {

  }

  ngOnInit() {
  }

}
