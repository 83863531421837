import {Action} from '@ngrx/store';

export enum AuthActions {
  AuthStart= "[AuthStart] for auth effect",
  AuthInProgress = "[Auth in progress] to set loading",
  AuthSuccess = '[Auth sucess] for login',
  LogOutAction = '[Log out ] for buttons',
  AuthFail = '[Auth fail] for login',
  GetToken = '[Get token] for refresh'
}

export class AuthStart implements Action{
  readonly type = AuthActions.AuthStart
  constructor(public login: string,
              public password: string,
              public server: string,
              public remember: boolean){}
}

export class AuthSucsess implements Action{
  readonly type = AuthActions.AuthSuccess
  constructor(public token: string,
              public exp: number,
              public login: string,
              public password: string,
              public remember: boolean,
              public server: string){}
}

export class AuthInProgress implements Action{
  readonly type = AuthActions.AuthInProgress
  constructor(public login: string,
              public password: string){}
}

export class LogOut implements Action{
  readonly type = AuthActions.LogOutAction
  constructor(){}
}


export class AuthFail implements Action{
  readonly type = AuthActions.AuthFail
  constructor(public error: string){}
}

export type AuthActionsTypes = AuthStart | AuthSucsess | AuthInProgress | AuthFail | LogOut
