import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import {SortSheetComponent} from './sortsheet/sortsheet.component';
import {MessagesService} from '../../services/messages.service';
import {filter} from 'rxjs/operators';
import {todayDateToString} from '../../helpers/helpers';
//import {ShowInfoWindow} from '../store/ui/uiactions';
import {AppState} from '../../store';
import {Store} from '@ngrx/store';
import {OpenInfoWindow} from '../../store/ui/uiactions';
import {Language} from 'angular-l10n';
/*** Universal view component
**** Base on parameters passed by router [:view] (current 'calls')
*** According to the parameter retrieve data from MAS
*** using main store action and proper effect (watch ngOnInit)
*** It is showing data about the format
**  data format: [{[column-name]: value,... }]
** it needs column list array (this.columnsNames)
** data is keeping in this.data
****/
@Component({
  selector: 'app-universal-view',
  templateUrl: './universal-view.component.html',
  styleUrls: ['./universal-view.component.scss']
})
export class UniversalViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Language() lang: string

  //names of columns accordin to data array
  @Input() columnsNames: string[]

  private _data: any;
  //data do dispaly
  @Input()
  set data(val: any){
    this._data = [...val]
  }

  get data(){
    return [...this._data]
  }

  @Input() windowTitle?: string =''

  //uses by sort pip to proper order of order data
  @Input() sortByField: {name: string, sort: boolean }[] = [];
  //for run ride
  @Output() startRide: EventEmitter<number> = new EventEmitter()
  @Output() showInfo: EventEmitter<any> = new EventEmitter()

  sortSheetRef: MatBottomSheetRef<SortSheetComponent> = null
  sortSheetOpen: boolean = false
  iconColour: string;

  @ViewChild('title') title: ElementRef

  constructor(public bottomSort: MatBottomSheet,
              public dialog: MessagesService,
              private _store: Store<AppState>) { }

//Call the proper action based on parameters retreived from ActivatedRoute
// parameter called 'view' determines showed data
// importatnt for view params: columnsNames (the fileds in data array)
// coulmsNames are used in template (translated to proper language)

  ngOnInit() {

  }

  ngAfterViewInit(){

  }

  //close bottom sheet durin closing component
  ngOnDestroy(){
   if(this.sortSheetRef){
       this.sortSheetRef.instance.close()
    }
  }

  //provides sorting
  //user add the column to sort list by clickin on the column's header
  //next click on header change dec or asc order


  sortBy(field: string){
    // console.log('field', field, 'sorBy', this.sortByField)
    //for refresh view purposese some sort of combination with
    //sortByField Array

    if(!this.sortByField.find((value =>value['name']===field))){
        //sort false ASC true DESC
        this.sortByField.push({name: field, sort: true})
        this.sortByField =[...this.sortByField]
        //open bottom sheet after added to order list (sortByField)
        this.openBottomSheet()


    }else{
      const id = this.findInFields(field)
      const tmp = [...this.sortByField]
      tmp[id]['sort'] = !this.sortByField[id]['sort']
      this.sortByField =[...tmp]
      //open bottom sheet
      this.openBottomSheet()
    }

  }

  rideStatusIcon(nr: number){
    switch (nr) {
      case 4:
      {
       return "close"
      }
      case 2:
      {
        return "done"
      }
      case 1:
      {
        return "hourglass_empty"
      }
      case 3:
      {
        return "hourglass_full"
      }
      default:
      {
       return 'check_box_outline_blank'
      }

    }
  }
  //open bottom sheet and subscribe
  //for posiibility to remove column from order list
  openBottomSheet(){
    this.sortSheetRef = this.bottomSort.open(SortSheetComponent,
      { hasBackdrop: false,
        panelClass: "bottom_sheet_sort",
        data: this.sortByField})

    this.sortSheetRef.instance.id.subscribe(id=>{
      //console.log('przez event emiter', id)
      let tmp = [...this.sortByField]
      tmp.splice(id, 1)
      this.sortByField = [...tmp]

    })
  }

  //helper function for template to indicate sort column
  findInFields(colName){
    return this.sortByField.findIndex((elem)=>elem['name']===colName)
  }

  openInfo(row: any){
    console.log('To jest on row', row)
    if(row['direction']!=='pitstop'){
        this._store.dispatch(new OpenInfoWindow(row['drivingOrders'][0]['pickupDate'], row['id']))
    }
  }

}
