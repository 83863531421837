import { Component, OnInit, OnDestroy } from '@angular/core';
import {Language} from 'angular-l10n';

@Component({
  selector: 'app-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.sass']
})
export class InprogressComponent implements OnInit, OnDestroy {

  @Language() lang: string

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
