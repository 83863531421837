import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AppState,
  getAppCurrentStatus,
  getShownOrderId,
  getInfoDayState,
  getInfoIdState,
  getOneRideIdState,
  getCurrentStatus
} from '../../store';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {CloseInfoWindow, SetAppStatus} from '../../store/ui/uiactions';
import {todayDateToString, transformDriverOrders} from '../../helpers/helpers';
import {SetCurrentRide} from '../../store/oneride/onerideactions';
import {MessagesService} from '../../services/messages.service';
import {filter, tap, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {appStatus} from '../../store/ui/uireducer';
import {Language} from 'angular-l10n';
import {CalculateNextRide,SetSharedRide} from '../../store/rideslist/rideslistactions';
import {SetEndPoint} from '../../store/georeducer/geoactions';
import {orderStatus, missionStatus} from '../../types/types';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  @Language() lang: string

  appStatusConst = {...appStatus}

  drivingOrders: any = [];
  data: any
  date: string;
  today: string;
  appStatus: string;
  rideStatus: {};
  pickUpTime: string;
  id: string;
  orderId: string = null;
  passengerid: number = 0;

  cols = ['key', 'value']

  private _subscription: Subscription = new Subscription()

  constructor(private _store: Store<AppState>,
              private _message: MessagesService,
              private _router: Router) { }

  ngOnInit() {

    this.today = todayDateToString()

    let sub$ =  this._store.select(getInfoDayState)
    .subscribe(
      day => this.date=day
    )
    this._subscription.add(sub$)

    sub$ = this._store.select(getInfoIdState)
      .subscribe(id=>{this.id=id})
    this._subscription.add(sub$)

   sub$ = this._store.select(getOneRideIdState, {date: this.date, id: this.id})
     .subscribe(data=>{
       console.log('Date', this.date, 'Id', this.id, 'Info Data', data)
       this.data = {...data}
       this.pickUpTime = this.data['drivingOrders'][0]['pickupTime']
     } )
    this._subscription.add(sub$)

    this.drivingOrders = [...transformDriverOrders(this.data)]
   //appStatus for show or hide ride button
   sub$ = this._store.select(getAppCurrentStatus).subscribe(s=>{
      console.log('appStatus info', s)
      this.appStatus=s
   })
   this._subscription.add(sub$)

   sub$ = this._store.select(getShownOrderId)
     .pipe(
      filter(id=>id!==null),
      map(id=>this.data['drivingOrders'].findIndex(item=>item['id']===id)),
      tap(id=>this.setCurrId(id))
    ).subscribe(
      id=>this.orderId=id
     )
    this._subscription.add(sub$)

    sub$ = this._store.select(getCurrentStatus).subscribe(
      ids=>{
        this.rideStatus={...ids}
        console.log('statussss', this.rideStatus[this.data['drivingOrders'][this.passengerid]['id']])
      }
    )

  }

  ngOnDestroy(){
    this._subscription.unsubscribe()
  }

  setCurrId(id: number){
    this.passengerid = id
    this.pickUpTime = this.data['drivingOrders'][id]['pickupTime']
    console.log('pick', this.pickUpTime)
  }

  startRide(){
    //for oneride state all passangers from ride
    let ids = []
    const initTargetLat = this.data.drivingOrders[0].startAddress.lat;
    const initTargetLng = this.data.drivingOrders[0].startAddress.lng;

    for(let i=0;i<this.data['drivingOrders'].length;i++){
      ids.push(this.data['drivingOrders'][i]['id'])
    }

    let sub$ = this._message.showDialog(
      {
        title: 'startride',
        message: '',
        yes: 'yes',
        no: 'no',
        result: 'yes'
      }
    )
     .pipe(
        filter(res=>res==='yes'),
        tap(()=>{
          //prepare for shared ride
          this._store.dispatch(new SetEndPoint({lat: initTargetLat, lng: initTargetLng, openMapInNewTab: true}))
          this._store.dispatch(new SetSharedRide(this.id, this.date))
          this._store.dispatch(new SetCurrentRide(this.id, ids, this.date ))
          this._store.dispatch(new SetAppStatus(appStatus.WaitingFor))
          //for the end action
          this._store.dispatch(new CalculateNextRide(this.id, this.date))
          this.closeInfo()
          //this._store.dispatch(new SetCurrentRideEffect())
          this._router.navigate(['/mainpage'])
        }
          )
      )
      .subscribe()
      this._subscription.add(sub$)
  }

  rideStatusText(): string{
    return missionStatus[this.data['status']]
  }

  orderStatusText(id: number): string{
    return orderStatus[id]
  }

  closeInfo(){
    this._store.dispatch(new CloseInfoWindow())
  }


}
