import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, switchMap, tap, map} from 'rxjs/operators';
import {EMPTY} from 'rxjs'
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState, getAuthLogin, getAuthToken} from '../../index';
import {MessagesService} from '../../../services/messages.service';
import {GetOneDay, GetOneDaySuccess, RidesListActions} from '../rideslistactions';
import {DataService} from '../../../services/data.service';

@Injectable()
export class GetOneDayEffect {

  private _token: string
  private _login: string

  constructor(private _actions$: Actions, private _store: Store<AppState>,
              private _router: Router,
              private _message: MessagesService,
              private _data: DataService) {
    this._store.select(getAuthToken)
      .subscribe(
        token=>this._token = token
      )

    this._store.select(getAuthLogin)
      .subscribe(
        login=>this._login = login
      )

  }

  @Effect()
  getone$ = this._actions$.pipe(
    ofType(RidesListActions.GetOneDayStart),
    tap((action)=>{
        this._store.dispatch(new GetOneDay(action['date']))
      }
    ),
    switchMap(action=>{
      return this._data.getPools(action['date'], this._token, this._login)
        .pipe(
          switchMap(data=>[new GetOneDaySuccess(action['date'], data)]),
          catchError((err)=>{
            this._message.showDialog({
              title: 'getdataerror',
              message: '',
              yes: '',
              no: 'ok',
              result: 'ok'
            })
            return EMPTY
          })
        )
    })
   )
}
