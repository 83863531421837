import { Injectable } from '@angular/core';
import {ModalComponent} from '../ui/modal/modal.component';
import {MatDialog} from '@angular/material';
import { appMessage } from '../types/types';
import {Observable} from 'rxjs';
import {DirectionmodalComponent} from '../ui/directionmodal/directionmodal.component';


@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(public dialog: MatDialog){ }

  showDialog(message: appMessage): Observable<string>{

    const dialogRef = this.dialog.open(ModalComponent, {
      width: '350px',
      position: {top: '200px', left: '35%'},
      data: {
          title: message.title,
          message: message.message,
          addInfo: message.addInfo,
          yes: message.yes,
          no: message.no,
          result:message.result
        }
      });

      return dialogRef.afterClosed();
}


  directionDialog(data: any):Observable<any>{

    const dialogRef = this.dialog.open(DirectionmodalComponent, {
      width: '450px',
      position: {top: '100px', left: '35%'},
      data: {...data}
    });

    return dialogRef.afterClosed();

  }

}
