import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {restconfig, servers} from '../config/config';
import {switchMap,} from 'rxjs/operators';
import {of, iif, throwError, Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http: HttpClient) { }

  getAuthToken(login: string, password: string, server: string): Observable<any>{

    const body = new HttpParams()
      .set('username', login)
      .set('password', password)
      .set('uuid', restconfig.uuid)
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})}

    return this._http.post(servers[server] +  restconfig.login, body, httpOptions).pipe(
      switchMap(data=>{
        return iif(()=>data['status']!==200,
          throwError(data),
          of(data)
          )
       })
      )

  }

}
