import {buttonStatusTypes, rideStatus} from '../../types/types';
import * as or from './onerideactions'

export interface buttonType {
  info: string;
  status: string;
}

export interface buttonsType {
  toclient: buttonType;
  //sms: buttonType;
  onclient: buttonType;
  start: buttonType;
  end: buttonType;
  cancelride: buttonType;
  cancel: buttonType;
  notshow: buttonType;
}

export interface buttons {
  [id: string]: buttonsType
}

export interface oneridestateType {
  currentRideId: string;
  currentCustomerId: string;
  pickUpDate: string;
  shared: boolean;
  adrIdx: number;
  ids: string[];
  endRide: boolean;
  status:{[id: string]: string};
  buttons: buttons;
}

export const initilButtonStatus = {
  toclient: {
    info: 'toclient',
    status: buttonStatusTypes.NOTDONEYET
  },
  /*
  sms: {
    info: 'SMS',
    status: buttonStatusTypes.NOTDONEYET
  },
   */
  onclient: {
    info: 'onclient',
    status: buttonStatusTypes.NOTDONEYET
  },
  start: {
    info: 'start',
    status: buttonStatusTypes.NOTDONEYET
  },

  end: {
    info: 'end',
    status: buttonStatusTypes.NOTDONEYET
  },
  notshow: {
    info: 'notshow',
    status: buttonStatusTypes.HIDEN
  },
  cancel: {
    info: 'cancel',
    status: buttonStatusTypes.HIDEN
  },

  cancelride: {
    info: 'cancelride',
    status: buttonStatusTypes.NOTDONEYET
  },

}

export const oneRideStateInitial = {
  currentRideId: null,
  currentCustomerId: null,
  pickUpDate: null,
  endRide: false,
  shared: false,
  adrIdx: 0,
  ids: [],
  status: {},
  buttons: null
}

export function oneridereducer(state: oneridestateType = oneRideStateInitial,
                               action: or.oneRideTypes) {

  switch (action.type) {
    case or.oneRideActions.ChangeAddressIdx:
    {
      return {...state, adrIdx: action.idx}
      break;
    }

    case or.oneRideActions.StartMission:
    {
      return {...state,
              status: {...setStatusForAll(state.ids, rideStatus.toclient)},
              buttons: {...setStartRide(state.ids)}
            }
      break;
    }
    case or.oneRideActions.ChangeButtonStatus:
    {
      return {...state,
        buttons: {...state.buttons,
          [state.currentCustomerId]: {...state.buttons[state.currentCustomerId],
            [action.key]: {info: state.buttons[state.currentCustomerId][action.key]['info']  , status: action.status}}
        }
      }
      break;
    }
    case or.oneRideActions.SetCurrentRide:
    {
      return {
        currentRideId: action.rideId,
        currentCustomerId: action.passengersIds[0],
        pickUpDate: action.pickUpDate,
        endRide: false,
        adrIdx: 0,
        shared: action.passengersIds.length>1? true : false ,
        ids: [...action.passengersIds],
        status: setStatus(action.passengersIds),
        buttons: {...setButtons(action.passengersIds)}
      }
      break;
    }
    case or.oneRideActions.SetCurrentStatus:
    {
      const status = {...state.status, [action.id]: action.status}
      return {
        ...state,
        endRide: checkEndAll(status, action.status, state.endRide) ,
        status: {...status}
        }
      break;
    }
    case or.oneRideActions.SetCurrentCustomerId:
    {
      return {...state, currentCustomerId: action.id}
      break;
    }
    case or.oneRideActions.ResetOneRide:
    {
      return oneRideStateInitial
    }
    default:
    {
      return state
    }
  }

}

export const customersIds = (state: oneridestateType)=>state.ids
export const allButtons = (state: oneridestateType)=>state.buttons
export const currentRide = (state: oneridestateType)=>state.currentRideId
export const currentCustomer = (state: oneridestateType)=>state.currentCustomerId
export const curretnPickUpDate = (state: oneridestateType)=>state.pickUpDate
export const currentStatus = (state: oneridestateType)=>state.status
export const endRide = (state: oneridestateType)=>state.endRide
export const addressIdx = (state: oneridestateType)=>state.adrIdx
export const sharedRide = (state: oneridestateType)=>state.shared

export const setButtons=(ids: string[])=>{

  const tmp: buttons = {}

  for(let i=0;i<ids.length;i++){
    tmp[ids[i]] = {...initilButtonStatus, toclient: {
        info: 'toclient',
        status: buttonStatusTypes.ACTIVE
      },
      cancelride: {
        info: 'cancelride',
        status: buttonStatusTypes.WARN
      },

    }
  }

  return tmp
}

export const setStatus=(ids: string[])=>{
  let tmp= {}
  for(let i=0;i<ids.length;i++){
    tmp[ids[i]] = ''
  }
  return tmp
}


export const setStatusForAll=(ids: string[], status: string):{[id: string]: string} =>{
  let tmp: {[id: string]: string} ={}
  for(let i=0;i<ids.length;i++) {
    tmp[ids[i]] = status
  }
  return tmp
}


export const setStartRide=(ids: string[]): buttons=>{
  let  tmp: buttons = {}
  for(let i=0;i<ids.length;i++){
    tmp[ids[i]] = {
      ...initilButtonStatus,
      toclient: {
        info: 'toclient',
        status: buttonStatusTypes.INPROGRESS
      },
      onclient: {
        info: 'onclient',
        status: buttonStatusTypes.ACTIVE
      }

    }
  }

  return tmp
}

export const checkEndAll=(status: {[id: string]: string}, currStatus: string, endRide: boolean):boolean=>{
   //check all rides are finished
  let end = true
  if(currStatus===rideStatus.end){
    for(let st in status){
      console.log(end, status[st])
      end = end && (status[st]===rideStatus.end)
    }
  }else {
    end = endRide
  }
    console.log('checked end', end)
    return end
}
