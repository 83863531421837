import * as au from './authactions'
import {decodeJwtToken} from '../../helpers/helpers';

export  interface authstate {
  login: string;
  password: string;
  token: string;
  expiredIn: number;
  inProgress: boolean;
 }

export const authStateInitial = {
  login: "",
  password: "",
  token: null,
  expiredIn: null,
  inProgress: false,
}

export function authreducer(state: authstate = authStateInitial, action: au.AuthActionsTypes) {
switch (action.type) {
  case au.AuthActions.AuthInProgress:
  {
    return {...state, inProgress: true }
  }
  case au.AuthActions.AuthSuccess:
  {
    let exp: number = 0
    if(action.token){
      localStorage.setItem('exp', (new Date((new Date().getTime() + action.exp)).getTime()).toString())
      localStorage.setItem('car', action.login)
      localStorage.setItem('pass', action.password)
      localStorage.setItem('server', action.server)
      localStorage.setItem('token', action.token)
      localStorage.setItem('remember', action.remember ? "true" : "false")


      return {...state,
        expiredIn: action.exp,
        token: action.token,
        login: action.login,
        password: action.password,
        inProgress: false
      }
    }else {
      return {...state, token: null}
    }
  }
  case au.AuthActions.LogOutAction:
  {
    if (localStorage.getItem('remember') !== "true")
    {
      localStorage.removeItem('car')
      localStorage.removeItem('pass')
      localStorage.removeItem('server')
      localStorage.removeItem('remember')
    }
    localStorage.removeItem('exp')
    localStorage.removeItem('token')

    return authStateInitial
  }

  case au.AuthActions.AuthFail:
  {
    return {...state, inProgress: false}
  }
  default:
    {
      return state
    }
  }
}

export const getToken = (state: authstate)=>state.token
export const getInProgress = (state: authstate)=>state.inProgress
export const getLogin = (state: authstate)=>state.login

