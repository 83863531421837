import {Action} from '@ngrx/store';

export enum UiActions {
  ToggleLeftDrawer = "[Toggle left drawer] for main page",
  ToggleRightDrawer = "[Toggle rigth drawer] for main page",
  OpenInfoWindow = '[Open info window] for view info',
  CloseInfoWindow = '[Close info windo] for info wiev',
  SetAppStatus = '[Set App Status] ',
  SetDispoServer = '[Set dispo server] for testing',
  ChengeShownOrderId = "[Change order Id] for show on mainpage",
  CloseRightDrawer = "[Close right drawer] for no more rides"
}

export class ChangeShownOrderId implements Action{
  readonly type = UiActions.ChengeShownOrderId
  constructor(public id: string){}
}


export class SetDispoServer implements Action{
  readonly type = UiActions.SetDispoServer
  constructor(public server: string){}
}


export class ToggleLeftDrawer implements Action{
  readonly type = UiActions.ToggleLeftDrawer
  constructor(){}
}

export class CloseleRightDrawer implements Action{
  readonly type = UiActions.CloseRightDrawer
  constructor(){}
}

export class ToggleRightDrawer implements Action{
  readonly type = UiActions.ToggleRightDrawer
  constructor(){}
}

export class OpenInfoWindow implements Action{
  readonly type = UiActions.OpenInfoWindow
  constructor(public date: string, public rideId: string, public orderId: string = null){}
}

export class CloseInfoWindow implements Action{
  readonly type = UiActions.CloseInfoWindow
  constructor(){}
}

export class SetAppStatus implements Action{
  readonly type = UiActions.SetAppStatus
  constructor(public status: string){}
}

export type UiActionsTypes = ToggleLeftDrawer | ToggleRightDrawer |
                  OpenInfoWindow | CloseInfoWindow | SetAppStatus | SetDispoServer |
                  ChangeShownOrderId | CloseleRightDrawer

