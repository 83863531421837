import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {
  AppState,
  getAllDaysLoadedState,
  getAppCurrentStatus,
  getCurrentCustomerIdState,
  getCurrentIds,
  getLeftDrawerStaus,
  getRightDrawerStaus,
  getShownOrderId
} from '../store';
import {Store} from '@ngrx/store';
import {ChangeShownOrderId, ToggleLeftDrawer, ToggleRightDrawer} from '../store/ui/uiactions';
import {appStatus} from '../store/ui/uireducer';
import {todayDateToString} from '../helpers/helpers';
import {SetCaltulationTriger} from '../store/georeducer/geoactions';
import {rideStatus} from '../types/types';


@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit, OnDestroy {

  leftDrawerOpen: boolean = false;
  rightDrawerOpen: boolean = false;
  ids$: Observable<string[]>
  currentId: string = null;
  currentPartId: string = null;
  passangerName: string;
  loaded: boolean;

  private subscription: Subscription =  new Subscription()

  constructor(private _store: Store<AppState>) { }

  ngOnInit() {
  let sub$ =  this._store.select(getLeftDrawerStaus)
    .subscribe(
      ld=>{
        this.leftDrawerOpen=ld
      }
    )
    this.subscription.add(sub$)

    sub$ = this._store.select(getRightDrawerStaus)
      .subscribe(
        rd=>{
          this.rightDrawerOpen = rd
        }
      )
    this.subscription.add(sub$)

    sub$ = this._store.select(getAppCurrentStatus)
      .pipe(
        filter(data=>data!==appStatus.DoingNothing),
        tap(()=>{

          if(rideStatus.toclient || rideStatus.start){
            this._store.dispatch(new SetCaltulationTriger(true))
          }

          console.log('Dont to nothing')

          sub$ = this._store.select(getShownOrderId)
           .subscribe(
             id =>this.currentId=id
           )

          sub$ = this._store.select(getCurrentCustomerIdState)
            .subscribe(
              id =>this.currentPartId=id
            )

          this.ids$ =  this._store.select(getCurrentIds)

        })

      ).subscribe()
    this.subscription.add(sub$)

    sub$ = this._store.select(getAllDaysLoadedState).pipe(
      filter(data=>data[todayDateToString()]===true),
      tap(()=>this.loaded=true)
      ).subscribe()

  }

  toggleLeft(): void{
    this._store.dispatch(new ToggleLeftDrawer())
  }

  changeClient(id: string){

    if(this.currentId!==id){
      if(!this.rightDrawerOpen){
        this._store.dispatch(new ToggleRightDrawer())
      }
      this._store.dispatch(new ChangeShownOrderId(id))
    }else{
     this._store.dispatch(new ToggleRightDrawer())
   }

  }

  passengerInfo(info: string){
    this.passangerName = info
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
