import {reducers} from './store';
import {StoreModule} from '@ngrx/store';
import {NgModule} from '@angular/core';

@NgModule({
  imports: [
    StoreModule.forFeature('appState', reducers
    )
  ],
  exports: [],
  declarations: [],
  providers: []
})
export class AppStoreModule {}
