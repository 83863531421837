import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {Subject, SubjectSubscriber} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-sortsheet',
  templateUrl: './sortsheet.component.html',
  styleUrls: ['./sortsheet.component.scss']
})
export class SortSheetComponent implements OnInit {
  //Emits id of deleted elem
  @Output('onDelete') id: EventEmitter<number> = new EventEmitter()

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private bottomSheetRef: MatBottomSheetRef<SortSheetComponent>) { }


  ngOnInit() {
  }

  close(){
    this.bottomSheetRef.dismiss()
  }

  deleteItem(id: number){
    this.id.emit(id)
    let tmp = [...this.data]
    tmp.splice(id, 1)
    this.data = [...tmp]
    if(!this.data.length){
      this.close()
    }
  }
}
