import { Component, OnInit, OnDestroy } from '@angular/core';
import {MessagesService} from '../services/messages.service';
import {LogOut} from '../store/auth/authactions';
import {tap, filter, switchMap, first, map, take} from 'rxjs/operators';
import {iif} from 'rxjs'
import {
  AppState,
  getAllButtonsState,
  getAppCurrentStatus,
  getCurrentAdrIdx,
  getCurrentCustomerIdState, getCurrentPickUpDate,
  getCurrentRideIdState, getCurrentRidesState, getCurrentStatus, getEndRideStatus,
  getLoadedState, getMapDirectionLink, getNextRideId, getOneRideIdState, getSharedRide, getTriggerState, getWholeDayRidedState
} from '../store';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import { Subscription, of } from 'rxjs';
import {
  CalculateNextRide,
  GetOneDayStart,
  ResetRidesState,
  SetSharedRide
} from '../store/rideslist/rideslistactions';
import {appStatus} from '../store/ui/uireducer';
import {buttonStatusTypes, dridingOrderStatus, rideStatus} from '../types/types';
import {
  ChangeAddressIdx,
  ChangeButtonStatus,
  ResetOneRide,
  SetCurrentCustomerId,
  SetCurrentRide,
  SetCurrentStatus,
  StartMission
} from '../store/oneride/onerideactions';
import {ChangeShownOrderId, CloseleRightDrawer, SetAppStatus, ToggleRightDrawer} from '../store/ui/uiactions';
import {CalculateDistance, SetEndPoint} from '../store/georeducer/geoactions';
import {StatusService} from '../services/status.service';
import {Language} from 'angular-l10n';
import { todayDateToString } from '../helpers/helpers';
import {EndRideOverride} from 'src/app/config/config';

export const yes=()=>filter(r=>r==="yes")

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit, OnDestroy {

  @Language() lang: string

  private _subscription: Subscription = new Subscription()
  private _loaded: boolean;
  private _currCustomerId: string = null;
  public buttons: {} = {};
  private _allButtons: {} = {};
  private _drivingOrders: any[] = [];
  private _rideId: string;

  private _pickUpDate: string
  private _rideStatus: {[id: string]: string}
  private _endRide: boolean;
  private _nextRideId: string;
  private _shared: boolean;
  private _currentAdrIdx: number;
  private _row: any

  private buttonTypes = {...buttonStatusTypes}

  constructor(private _message: MessagesService,
              private _store: Store<AppState>,
              private _status: StatusService,
              private _route: Router,
              ) { }

  ngOnInit() {
    let sub$ = this._store.select(getLoadedState)
      .subscribe(
        loaded => {
          this._loaded = loaded;
        }
      )
    this._subscription.add(sub$)

    //if app status in ontheride
    sub$ =  this._store.select(getAppCurrentStatus)
      .pipe(
        filter(data=>data!==appStatus.DoingNothing),
        tap(()=>{

          sub$ = this._store.select(getAllButtonsState)
            .subscribe(buttons=>{
              this._allButtons = {...buttons}
              if(this._currCustomerId){
                this.buttons = {...this._allButtons[this._currCustomerId]}
              }
            }
           )
          this._subscription.add(sub$)

          console.log('Buttons')
          sub$ = this._store.select(getCurrentCustomerIdState)
            .subscribe(id=>{
                this._currCustomerId=id
                this.buttons = {...this._allButtons[id]}
              }
              )
              this._subscription.add(sub$)

          sub$ = this._store.select(getCurrentPickUpDate).subscribe(
            date=>{
              this._pickUpDate=date
            }
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getCurrentStatus).subscribe(
            status=>{
              this._rideStatus={...status}
            }
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getEndRideStatus).subscribe(
            end=>this._endRide = end
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getNextRideId).subscribe(
            next=>this._nextRideId = next
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getSharedRide).subscribe(
            rd=>this._shared = rd
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getCurrentAdrIdx).subscribe(
            ix=>this._currentAdrIdx = ix
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getCurrentRideIdState).subscribe(
            id =>this._rideId = id
          )
          this._subscription.add(sub$)

          sub$ = this._store.select(getCurrentRidesState)
            .subscribe(
            row=>{
              this._row = {...row}
              if(this._row){
                console.log('row,row, row', this._row)
                if(this._row.hasOwnProperty('shared')){
                  this._store.dispatch(new SetCurrentCustomerId(this._row['shared'][this._currentAdrIdx]['orderId']))
                }
              }
            }

          )
          this._subscription.add(sub$)


        })
      ).subscribe()
    this._subscription.add(sub$)
    //set the current custommer

  }

  logout(){
    const sub$ = this._message.showDialog({
      title: "logout",
      message: "logoutinfo",
      yes: "yes",
      no: "no",
      result: "yes"
    }).pipe(
      filter((result)=>result==='yes'),
      tap(()=>{
        this._store.dispatch(new LogOut())
        this._store.dispatch(new SetAppStatus(appStatus.DoingNothing))
        this._store.dispatch(new ResetOneRide())
        this._store.dispatch(new ResetRidesState())
        this._route.navigate(['/login'])
      })
    ).subscribe(r=>r)

    this._subscription.add(sub$)
  }

  openRidesList(){
    this._route.navigate(["/rides"]);
  }

  openDailyCarList() {
    this._route.navigate(["/daily-car-list"]);
  }

  ngOnDestroy(){
    this._subscription.unsubscribe()
  }

  nextTurn(e, key){
     console.log('key', key, this.buttons[key]['status'], this._currCustomerId)
    //cannot click twice the same key

    if(this._currCustomerId!==null){
      console.log('after', key)
      if(this.buttons[key]['status']!==buttonStatusTypes.ACTIVE && this.buttons[key]['status']!==buttonStatusTypes.WARN ){
        key = ''
      }
    } else {
      key = ''
    }

    console.log('after', key)

    let endlat = null;
    let endlng = null;
    let sub$ = new Subscription()

    switch (key){
      case "toclient":
        {
        sub$ = this._message.showDialog({
          title: 'startride', //translate in template keys dialog. + start
          message: 'startinfo',
          yes: "Start",
          no: 'Discard',
          result: 'start'
          }).pipe(
             filter(res=>res==='start'),
             tap(()=>{
               //Idx = 0 this means we are starting ride
               //we need to set mission status
               //and prepare for whole mission

               if(this._currentAdrIdx===0){
                 //this._store.dispatch(new StartMission())
                 sub$ = this._status.setMissionStatus(dridingOrderStatus.checkin, this._rideId).pipe(first()).subscribe(
                   data=>console.log('setStatus', data)
                 )
                 this._subscription.add(sub$)
               }

               this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))

               this._store.dispatch(new SetCurrentCustomerId(this._row['shared'][this._currentAdrIdx]['orderId']))
               this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))
               this._store.dispatch(new SetCurrentStatus(this._currCustomerId, rideStatus.toclient))
               this._store.dispatch(new CalculateDistance(this._row['shared'][this._currentAdrIdx]['lat'],
               this._row['shared'][this._currentAdrIdx]['lng']))


               sub$ = this._status.setOrderStatus(dridingOrderStatus.checkin, this._currCustomerId).pipe(first()).subscribe(
                 data=>console.log('setStatus', data)
               )
               this._subscription.add(sub$)

               //this._store.dispatch(new ChangeButtonStatus('cancelride', buttonStatusTypes.NOTDONEYET))
               this._store.dispatch(new ChangeButtonStatus(key, buttonStatusTypes.INPROGRESS))
               this._store.dispatch(new ChangeButtonStatus('onclient', buttonStatusTypes.ACTIVE))
               this._store.dispatch(new SetAppStatus(appStatus.OnRide))

               if(EndRideOverride){
                 this._store.dispatch(new ChangeButtonStatus('end', buttonStatusTypes.ACTIVE))
               }

               this._store.dispatch(new GetOneDayStart(todayDateToString()))

             })
           ).subscribe()
       break;
       }
        case 'onclient':
        {
          sub$ = this._message.showDialog({
            title: 'arrive',
            message: 'arriveinfo',
            yes: "yes",
            no: 'no',
            result: 'yes'
          }).pipe(
            filter(res=>res==='yes'),
            tap(()=>{
              this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))
              this._store.dispatch(new ChangeButtonStatus('toclient', buttonStatusTypes.DONE))
              this._store.dispatch(new ChangeButtonStatus(key, buttonStatusTypes.INPROGRESS))
              this._store.dispatch(new ChangeButtonStatus('start', buttonStatusTypes.ACTIVE))
              this._store.dispatch(new ChangeButtonStatus('cancel', buttonStatusTypes.WARN))
              this._store.dispatch(new ChangeButtonStatus('notshow', buttonStatusTypes.WARN))
              //this._store.dispatch(new ChangeButtonStatus('cancelride', buttonStatusTypes.NOTDONEYET))
              this._store.dispatch(new SetCurrentStatus(this._currCustomerId, rideStatus.onclient))

            })
          ).subscribe()
          this._subscription.add(sub$)
          break;
        }
        case 'start':
        {
          sub$ = sub$ = this._message.showDialog({
            title: 'startto',
            message: 'starttoinfo',
            yes: "yes",
            no: 'no',
            result: 'yes'
          }).pipe(
            filter(res=>res==='yes'),
            tap(()=>{
              this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))
              this._store.dispatch(new ChangeButtonStatus('cancel', buttonStatusTypes.HIDEN))
              //this._store.dispatch(new ChangeButtonStatus('cancelride', buttonStatusTypes.NOTDONEYET))
              this._store.dispatch(new ChangeButtonStatus('notshow', buttonStatusTypes.HIDEN))

              this._store.dispatch(new ChangeButtonStatus('onclient', buttonStatusTypes.DONE))
              this._store.dispatch(new ChangeButtonStatus(key, buttonStatusTypes.INPROGRESS))
              this._store.dispatch(new ChangeButtonStatus('end', buttonStatusTypes.ACTIVE))
              this._store.dispatch(new SetCurrentStatus(this._currCustomerId, rideStatus.start))


            let sb$ = this._status.setMissionStatus(dridingOrderStatus.arrive, this._rideId).pipe(first()).subscribe(
                data=>console.log('setMissionStatus', data,  'rideid', this._rideId)
              )
              this._subscription.add(sb$)

              sb$ = this._status.setOrderStatus(dridingOrderStatus.arrive, this._currCustomerId).pipe(first()).subscribe(
                data=>console.log('setOrderStatusXXX', data)
              )
              this._subscription.add(sb$)
              //refresh day
              this._store.dispatch(new GetOneDayStart(this._pickUpDate))


              //the next point could be nest start or end
              this._store.dispatch(new ChangeAddressIdx(this._currentAdrIdx+1))

              this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))

              this._store.dispatch(new SetCurrentCustomerId(this._row['shared'][this._currentAdrIdx]['orderId']))
              this._store.dispatch(new CalculateDistance(this._row['shared'][this._currentAdrIdx]['lat'],
              this._row['shared'][this._currentAdrIdx]['lng']))


            })
          ).subscribe()
          this._subscription.add(sub$)
      break;
      }
      case 'end':
      {
        sub$ = this._message.showDialog(
          {
            title: 'end',
            message: 'endinfo',
            yes: "yes",
            no: 'no',
            result: 'yes'
          }
        ).pipe(
         filter(re=>re==='yes'),
         tap(()=>{

           //this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))

           this._store.dispatch(new ChangeButtonStatus('start', buttonStatusTypes.DONE))
           this._store.dispatch(new ChangeButtonStatus(key, buttonStatusTypes.DONE))
           this._store.dispatch(new ChangeButtonStatus('cancelride', buttonStatusTypes.HIDEN))
           this._store.dispatch(new SetCurrentStatus(this._currCustomerId, rideStatus.end))

           let sb$ = this._status.setOrderStatus(dridingOrderStatus.depart, this._currCustomerId).pipe(first()).subscribe(
             data=>console.log('setOrderStatus', data)
           )
           this._subscription.add(sb$)

           //refresh day
           this._store.dispatch(new GetOneDayStart(this._pickUpDate))

           if(!this._endRide){
             console.log('more rides', this._row)
             this._store.dispatch(new ChangeAddressIdx(this._currentAdrIdx+1))

             this._store.dispatch(new ChangeShownOrderId(this._row['shared'][this._currentAdrIdx]['orderId']))
             this._store.dispatch(new SetCurrentCustomerId(this._row['shared'][this._currentAdrIdx]['orderId']))

             this._store.dispatch(new CalculateDistance(this._row['shared'][this._currentAdrIdx]['lat'],
             this._row['shared'][this._currentAdrIdx]['lng']))

             }else {

             sb$ = this._status.setMissionStatus(dridingOrderStatus.depart, this._rideId).pipe(first()).subscribe(
               data=>{
                 this._store.dispatch(new GetOneDayStart(this._pickUpDate))
                 console.log('setMissionStatus', data,  'rideid', this._rideId)}
             )
             this._subscription.add(sb$)

             console.log('no more rides')
            sb$ = this._message.showDialog({
                 title: "ridecompleted",
                 message:"",
                 yes: "",
                 no: "Ok",
                 result:"ok"
              }).pipe(
                switchMap(()=>iif(()=>this._nextRideId!==null,

                  of(this._nextRideId).pipe(
                    switchMap(()=>this._message.showDialog(
                      {
                        title: "nextride",
                        message: "",
                        yes: "yes",
                        no: "no",
                        result: "yes"
                      }
                      ).pipe(
                      tap((res)=>{
                        if(res!=='yes'){this._store.dispatch(new SetAppStatus(appStatus.DoingNothing))}
                      }),
                      filter(res=>res==="yes"),
                      switchMap(()=>this._store.select(getWholeDayRidedState, {date: this._pickUpDate})
                        .pipe(
                          take(1),
                          tap(data=>{

                            //console.log('data', data, nextId)
                            let next =  data.find(item=>item['id']===this._nextRideId)
                            let ids = []
                            for (let i=0; i<next['drivingOrders'].length; i++){
                              ids.push(next['drivingOrders'][i]['id'])
                            }
                            this._store.dispatch(new SetAppStatus(appStatus.DoingNothing))

                            this._store.dispatch(new SetEndPoint({lat: 0.0, lng: 0.0, openMapInNewTab: false}))
                            this._store.dispatch(new ChangeAddressIdx(0))

                            this._store.dispatch(new SetSharedRide(this._nextRideId, this._pickUpDate))
                            this._store.dispatch(new SetCurrentRide(this._nextRideId, ids, this._pickUpDate ))
                            this._store.dispatch(new SetAppStatus(appStatus.OnRide))


                            this._store.dispatch(new SetCurrentCustomerId(this._row['shared'][0]['orderId']))
                            this._store.dispatch(new ChangeShownOrderId(this._row['shared'][0]['orderId']))
                            this._store.dispatch(new CalculateNextRide(this._nextRideId, this._pickUpDate))

                          })
                        )
                      )
                      )
                    )
                  )
                  ,

                  of(null).pipe(
                    switchMap(()=>this._message.showDialog(
                      {
                        title: "nomorerides",
                        message: "",
                        yes: "",
                        no: "Ok",
                        result: "ok"
                      }
                    ).pipe(
                      tap(()=>{
                        this._store.dispatch(new SetEndPoint({lat: 0.0, lng: 0.0, openMapInNewTab: true}))
                        this._store.dispatch(new SetAppStatus(appStatus.DoingNothing))
                        this._store.dispatch(new ResetOneRide())
                        this._store.dispatch(new CloseleRightDrawer())
                      })
                    )
                   ))
                  ))
              ).subscribe()
              this._subscription.add(sb$)
           }

         })
        ).subscribe()
        this._subscription.add(sub$)

      break;
      }
      case "cancel":
      {

      }
      case "notshow":
      {
        sub$ = this._message.showDialog({
          title: "notshow",
          message: "",
          yes: "confirm",
          no: "discard",
          result: "yes"
        }).pipe(
          yes(),
          tap(()=>{

            let sb$ = this._status.setOrderStatus(dridingOrderStatus.cancel, this._currCustomerId).pipe(first()).subscribe(
              data=>{ console.log(data)
                //refresh data
                this._store.dispatch(new GetOneDayStart(this._pickUpDate))
                //reset one ride state
                //this._store.dispatch(new ResetOneRide())
                //Should add reset button for this ride
                this._store.dispatch(new ChangeButtonStatus('onclient', buttonStatusTypes.DONE))
                this._store.dispatch(new ChangeButtonStatus('start', buttonStatusTypes.DONE))
                this._store.dispatch(new ChangeButtonStatus('end', buttonStatusTypes.DONE))
                this._store.dispatch(new ChangeButtonStatus('notshow', buttonStatusTypes.HIDEN))
                this._store.dispatch(new ChangeButtonStatus('cancel', buttonStatusTypes.HIDEN))
                this._store.dispatch(new ChangeButtonStatus('cancelride', buttonStatusTypes.DONE))

                this._message.showDialog({
                  title: "canceled",
                  message: "",
                  yes: "",
                  no: "ok",
                  result: "ok"
                })

                if(!this._shared){
                  sb$ = this._status.setMissionStatus(dridingOrderStatus.cancel, this._rideId).pipe(first()).subscribe()
                }

                console.log( data )
              }
            )
            this._subscription.add(sb$)
          })
        ).subscribe()
        this._subscription.add(sub$)

        break;
      }
      case "cancelride":
      {
        sub$ = this._message.showDialog({
          title: "cancelride",
          message: "cancelrideinfo",
          yes: "confirm",
          no: "discard",
          result: "yes"
        }).pipe(
          yes(),
          tap(()=>{

            let sb$ = this._status.setMissionStatus(dridingOrderStatus.cancel, this._rideId).pipe(first()).subscribe(
              data=>{
                //refresh data
                this._store.dispatch(new GetOneDayStart(this._pickUpDate))
                //reset one ride state
                this._store.dispatch(new ResetOneRide())

                this._message.showDialog({
                  title: "canceled",
                  message: "",
                  yes: "",
                  no: "ok",
                  result: "ok"
                })

                console.log( data )
              }
            )
            this._subscription.add(sb$)
          })
        ).subscribe()
        this._subscription.add(sub$)
        break;
      }
     }
    }




}
