import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, filter, tap} from 'rxjs/operators';
import {of} from 'rxjs'
import {Store} from '@ngrx/store';

import {AppState, getWholeDayRidedState} from '../..';



import {todayDateToString} from '../../../helpers/helpers';
import {firstAvaliable} from '../rideslistreducer';
import {ChangeShownOrderId, SetAppStatus} from '../../ui/uiactions';

import {appStatus} from '../../ui/uireducer';
import {CalculateNextRide, RidesListActions, SetSharedRide} from '../rideslistactions';
import {SetCurrentRide} from '../../oneride/onerideactions';
import {Router} from '@angular/router';
import {MessagesService} from '../../../services/messages.service';

@Injectable()
export class SetFirstAvaliableEffect {

  private daylirides: {}[]


  constructor(private _actions$: Actions, private _store: Store<AppState>,
              private _router: Router, private _message: MessagesService) {

    this._store.select(getWholeDayRidedState, {date: todayDateToString()})
      .subscribe(
        data=>this.daylirides = [...data]
      )
  }

  @Effect()
  getone$ = this._actions$.pipe(
    ofType(RidesListActions.SetFirstAvaliable),
    switchMap(()=>of(firstAvaliable(this.daylirides))),
    tap(id=>{
      if(id===null){
       this._message.showDialog({
         title: "norides",
         message:"",
         yes:"",
         no: "ok",
         result: "ok"
       })
      this._router.navigate(['/mainpage'])
      }
    }),
    filter(id=>id!==null),
    switchMap((id)=>{

        const date = todayDateToString()
        let ids = []
        let ride = this.daylirides.find(item=>item['id']===id)
          console.log('ride', ride)
        for(let i=0;i<ride['drivingOrders'].length;i++){
          ids.push(ride['drivingOrders'][i]['id'])
        }

      console.log('id dla first', ids, id)
        return [
          (new SetAppStatus(appStatus.DoingNothing)),
          (new SetSharedRide(id, date)),
          (new ChangeShownOrderId(ids[0])),
          (new SetCurrentRide(id, ids, date )),
          (new CalculateNextRide(id, date)),
          (new SetAppStatus(appStatus.WaitingFor))
        ]
      }
    ),
    tap(()=>this._router.navigate['/mainpage'])
  )
}
