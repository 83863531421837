import {Action} from "@ngrx/store"

export enum GeoActions {
  SetCurrentPosition = '[Set current position] for map',
  SetCurrentCenter = '[Set current center] for map component',
  SetDirection = '[Set direction]  for ride',
  CalculateDistance = '[Calculate distnce] map componenet',
  ResetCalculationTriger = '[Reset calculation triger] for map component',
  SetCalculationTriger = '[Set calculation triger] for map component',
  SetEndPoint = "[Set end point] for ride",
  SetOpenMapInNewTab = "[Set open map in new tab] for ride"
}

export class SetCurrentPosition implements Action {
  readonly type = GeoActions.SetCurrentPosition
  constructor(public lat: number, public lng: number){}
}

export class SetEndPoint implements Action{
  readonly type = GeoActions.SetEndPoint
  constructor(public point: {lat: number, lng: number, openMapInNewTab: boolean}){}
}

export class SetOpenMapInNewTab implements Action{
  readonly type = GeoActions.SetOpenMapInNewTab
  constructor(public openMapInNewTab: boolean){}
}

export class SetCurrentCenter implements Action {
  readonly type = GeoActions.SetCurrentCenter
  constructor(public lat: number, public lng: number){}
}

export class SetDirection implements Action {
  readonly type = GeoActions.SetDirection
  constructor(public distance: number, public time: number){}
}

export class CalculateDistance implements Action {
  readonly type = GeoActions.CalculateDistance
  constructor(public lat: number, public lng: number){}
}

export class ResetCaltulationTriger implements Action {
  readonly type = GeoActions.ResetCalculationTriger
  constructor(){}
}

export class SetCaltulationTriger implements Action {
  readonly type = GeoActions.SetCalculationTriger
  constructor(public tr: boolean){}
}


export type GeoActionTypes = SetCurrentPosition | SetCurrentCenter | SetDirection
                        | CalculateDistance | ResetCaltulationTriger | SetEndPoint | SetCaltulationTriger
                        | SetOpenMapInNewTab
