import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {switchMap, take, takeLast, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState, getAppState} from '../../index';
import {CalculateNextRide, RidesListActions, SetSharedRide, SetCurrentRideEf} from '../rideslistactions';
import {ChangeShownOrderId, SetAppStatus} from '../../ui/uiactions';
import {ChangeAddressIdx, SetCurrentRide} from '../../oneride/onerideactions';
import {appStatus} from '../../ui/uireducer';
import {EMPTY} from 'rxjs';


@Injectable()
export class SetCurrentRideEffect {


  constructor(private _actions$: Actions, private _store: Store<AppState>) {


  }

  @Effect()
  setcurr$ = this._actions$.pipe(
    ofType(RidesListActions.SetCurrentRideEffect),
    switchMap((action)=>this._store.select(getAppState).pipe(
      take(1),
      switchMap(state=>{
        console.log('kurwa', action)
        let nextRide = state.rides.dailyrides[action['date']].find(item=>item['id']===action['id'])

        let ids = []
        for(let i=0;i<nextRide['drivingOrders'].length;i++){
          ids.push(nextRide['drivingOrders'][i]['id'])
        }

        this._store.dispatch(new SetCurrentRide(action['id'], ids, action['date'] ))
        this._store.dispatch(new ChangeShownOrderId(nextRide['share'][0]))
        this._store.dispatch ( new SetAppStatus(appStatus.OnRide))
        this._store.dispatch  (new CalculateNextRide(action['id'], action['date']))

        return EMPTY

      })
    ))
   )
}
