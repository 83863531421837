import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainpageComponent} from './mainpage/mainpage.component';
import {LoginpageComponent} from './loginpage/loginpage.component';
import {NotFoundComponent} from './ui/notfound/notfound.component';

import {AuthguardService} from './services/authguard.service';
import {RideslistComponent} from './rideslist/rideslist.component';
import {InprogressComponent} from './ui/inprogress/inprogress.component';
import { DailyCarListComponent } from './daily-car-list/daily-car-list.component';

const routes: Routes = [
  {path: "", redirectTo: "mainpage", pathMatch: 'full'},
  {path: "mainpage", component: MainpageComponent, canActivate: [AuthguardService] },
  {path: "rides", component: RideslistComponent, canActivate: [AuthguardService]},
  {path: "daily-car-list", component: DailyCarListComponent, canActivate: [AuthguardService]},
  {path: "login", component: LoginpageComponent },
  {path: "inprogress", component: InprogressComponent, canActivate: [AuthguardService]},
  {path: "**", component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
