import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as auState from './auth/authreducer'
import * as uiState from './ui/uireducer';
import * as ridesState from './rideslist/rideslistreducer';
import * as oneRideState from './oneride/oneridereducer'
import * as geoState from './georeducer/georeducer'
import {ridesliststate} from './rideslist/rideslistreducer';
import {rideStatus} from '../types/types';

export interface AppState {
  auth: auState.authstate;
  ui: uiState.uistate;
  rides: ridesState.ridesliststate;
  oneride: oneRideState.oneridestateType;
  geo: geoState.geostate;
}

export const reducers: ActionReducerMap<AppState>={
  auth: auState.authreducer,
  ui: uiState.uireducer,
  rides: ridesState.rideslistreducer,
  oneride: oneRideState.oneridereducer,
  geo: geoState.georeducer
}


export const getAppState = createFeatureSelector<AppState>('appState')
//auth state selector
export const getAuthState = createSelector(
  getAppState,
  (state: AppState)=>state.auth
)

export const getAuthToken = createSelector(
  getAuthState,
  auState.getToken
)

export const getInProgressStatus = createSelector(
  getAuthState,
  auState.getInProgress
)

export const getAuthLogin = createSelector(
  getAuthState,
  auState.getLogin
)

//ui state selectors
export const getUiState = createSelector(
  getAppState,
  (state: AppState)=>state.ui
)

export const getShownOrderId = createSelector(
  getUiState,
  uiState.getOrderId
)


export const getAppCurrentStatus = createSelector(
  getUiState,
  uiState.getAppStatus
)

export const getRightDrawerStaus = createSelector(
  getUiState,
  uiState.getRightDrawerState
)

export const getLeftDrawerStaus = createSelector(
  getUiState,
  uiState.getLeftDrawerState
)

export const getInfoIdState = createSelector(
  getUiState,
  uiState.getInfoId
)

export const getInfoDayState = createSelector(
  getUiState,
  uiState.getInfoDay
)

export const getInfoOpenedState = createSelector(
  getUiState,
  uiState.getOpenInfo
)

export const getServerName = createSelector(
  getUiState,
  uiState.getServer
)

//rides list selectors

export const getRidesState = createSelector(
  getAppState,
  (state: AppState)=>state.rides
)

export const getCurrentRidesState = createSelector(
  getRidesState,
  ridesState.currentRide
)

export const getAllRidesState = createSelector(
  getRidesState,
  ridesState.getAllRides
)

export const getDayLoadedState = createSelector(
  getRidesState,
  (state: ridesliststate, props: any)=>state.dayloaded[props.date]
)

export const getAllDaysLoadedState = createSelector(
  getRidesState,
  ridesState.getDayLoaded
)

export const getLoadedState = createSelector(
  getRidesState,
  ridesState.getAllLoaded
)

export const getLoadingdState = createSelector(
  getRidesState,
  ridesState.getLoading
)

export const  getWholeDayRidedState = createSelector(
  getRidesState,
  (state, props)=>{
    return state.dailyrides[props.date]
  }
)

export const getDailyCarList = createSelector(
  getRidesState,
  ridesState.getDailyCarList
)


export const  getOneRideIdState = createSelector(
  getRidesState,
  (state, props)=>{
    return state.dailyrides[props.date].find((item)=>item['id']===props.id)
  }
)

export const getNextRideId = createSelector(
  getRidesState,
  ridesState.nextRideId
)

//oneride state

export const getOneRideState = createSelector(
  getAppState,
  (state: AppState)=>state.oneride
)

export const getCurrentAdrIdx = createSelector(
  getOneRideState,
  oneRideState.addressIdx
)

export const getSharedRide = createSelector(
  getOneRideState,
  oneRideState.sharedRide
)

export const getCurrentIds = createSelector(
  getOneRideState,
  oneRideState.customersIds
)

export const getCurrentStatus = createSelector(
  getOneRideState,
  oneRideState.currentStatus
)

export const getCurrentPickUpDate = createSelector(
  getOneRideState,
  oneRideState.curretnPickUpDate
)

export  const getCurrentRideIdState = createSelector(
  getOneRideState,
  oneRideState.currentRide
)

export  const getCurrentCustomerIdState = createSelector(
  getOneRideState,
  oneRideState.currentCustomer
)

export const getAllButtonsState = createSelector(
  getOneRideState,
  oneRideState.allButtons
)

export const getButtonsById = createSelector(
  getOneRideState,
  (state, props)=>state.buttons[props.id]
)

export const getEndRideStatus = createSelector(
  getOneRideState,
  oneRideState.endRide
)

//geto state selectors

export const getGeoState = createSelector(
  getAppState,
  (state: AppState)=>state.geo
)

export const getPosition = createSelector(
  getGeoState,
  (state)=>({lat: state.currLat, lng: state.currLng})
)

export const getEndPoint = createSelector(
  getGeoState,
  (state)=>({lat: state.endLat, lng: state.endLng, openMapInNewTab: state.openMapInNewTab})
)


export const getTriggerState = createSelector(
  getGeoState,
 geoState.getTrigger
)

export const getMapDirectionLink = createSelector(
  getGeoState,
  geoState.directionLink
)
