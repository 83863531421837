import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import {MaterialModules} from './material.module';
import {StoreModule} from '@ngrx/store';
import {AppStoreModule} from './store.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import { ModalComponent } from './ui/modal/modal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { NotFoundComponent } from './ui/notfound/notfound.component';
import {SpinnerComponent} from './ui/spinner/spinner.component';
import {EffectsModule} from '@ngrx/effects';
import {LoginEffects} from './store/auth/effects/auth.effect';
import {SupperbuttonComponent} from './ui/supperbutton/supperbutton.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { RideslistComponent } from './rideslist/rideslist.component';
import {AllRidesEffects} from './store/rideslist/effects/getall.effect';
import {GetOneDayEffect} from './store/rideslist/effects/getoneday.effect';

import {UniversalViewComponent} from './rideslist/universal-view/universal-view.component';
import {KeysPipe} from './helpers/keys.pipe';
import {SortPipe} from './helpers/sort.pipe';
import {SortSheetComponent} from './rideslist/universal-view/sortsheet/sortsheet.component';
import { WeekridesComponent } from './rideslist/weekrides/weekrides.component';
import { InfoComponent } from './rideslist/info/info.component';
import {InfoboxComponent} from './infoboxes/infobox/infobox.component';
import {InfoboxesComponent} from './infoboxes/infoboxes.component';
import { MapComponent } from './mainpage/map/map.component';
import {SafePipe} from './helpers/safe.pipe';
import { DirectionmodalComponent } from './ui/directionmodal/directionmodal.component';

import { L10nConfig, L10nLoader, TranslationModule, StorageStrategy, ProviderType, LogLevel } from 'angular-l10n';
import {SetFirstAvaliableEffect} from './store/rideslist/effects/firstavaliable.effect';
import { InprogressComponent } from './ui/inprogress/inprogress.component';
import {SetCurrentRideEffect} from './store/rideslist/effects/setcurrentride.effect';
import {GetOneDayStartEffect} from './store/rideslist/effects/getonedaystart.effect';
import { DailyCarListComponent } from './daily-car-list/daily-car-list.component';


const l10nConfig: L10nConfig = {
  logger: {
    level: LogLevel.Warn
  },
  locale: {
    languages: [
      { code: 'ge', dir: 'ltr' },
      { code: 'en', dir: 'ltr' }
    ],
    language: 'ge',
    storage: StorageStrategy.Cookie
  },
  translation: {
    providers: [
      { type: ProviderType.Static, prefix: './assets/locale/' }
    ],
    caching: true,
    composedKeySeparator: '.',
    missingValue: 'No key'
  }
};


@NgModule({
  declarations: [
    AppComponent,
    MainpageComponent,
    ModalComponent,
    LoginpageComponent,
    NotFoundComponent,
    SpinnerComponent,
    SupperbuttonComponent,
    ButtonsComponent,
    RideslistComponent,
    UniversalViewComponent,
    KeysPipe,
    SortPipe,
    SafePipe,
    SortSheetComponent,
    WeekridesComponent,
    InfoComponent,
    InfoboxComponent,
    InfoboxesComponent,
    MapComponent,
    DirectionmodalComponent,
    InprogressComponent,
    DailyCarListComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    MaterialModules,
    HttpClientModule,
    AppStoreModule,
    FormsModule,
    TranslationModule.forRoot(l10nConfig),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([LoginEffects, AllRidesEffects, GetOneDayEffect,
          SetFirstAvaliableEffect, SetCurrentRideEffect, GetOneDayStartEffect]),
    StoreDevtoolsModule.instrument()
  ],
  entryComponents:[
   ModalComponent,
   SortSheetComponent,
   DirectionmodalComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public l10nLoader: L10nLoader){
    this.l10nLoader.load();
  }
}
