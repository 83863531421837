import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Store} from '@ngrx/store'
import {AuthStart} from '../store/auth/authactions';
import {AppState, getInProgressStatus} from '../store';
import {Language, LocaleService, TranslationService} from 'angular-l10n';
import {servers} from '../config/config';

@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit, OnDestroy {

  @Language() lang: string

  servers: any = {...servers}
  loginn: string;
  pass: string;
  server: string;
  remeberMe: boolean;

  inProgress: boolean = false;

  constructor(private _store: Store<AppState>, public translation: TranslationService) { }

  ngOnInit() {
    console.log('default', this.lang)
    if(localStorage.getItem('car') && localStorage.getItem('remember') == "true"){
      this.loginn = localStorage.getItem('car')
      this.pass = localStorage.getItem('pass')

      if(localStorage.getItem('server')){
           this.server =  localStorage.getItem('server')
         }else{
          this.server = 'dev2'
      }

      this.remeberMe = true
    }

    this._store.select(getInProgressStatus).subscribe(
      inp=>this.inProgress=inp
    )
  }

  loginTixi(values: any){
    console.log('Zapamietuje', values)
    this._store.dispatch(new AuthStart(values.login, values.password, values.server , this.remeberMe))
  }

  tr(source: string){
    return this.translation.translate(source)
  }

  ngOnDestroy(){

  }
}
