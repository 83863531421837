import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {scan, switchMap, tap, map, mergeMap, concatMap, mergeAll, last, finalize, toArray} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState, getAuthLogin, getAuthToken} from '../../index';
import {MessagesService} from '../../../services/messages.service';
import {GetAllRides, GetAllRidesSuccess, GetOneDaySuccess, RidesListActions, SetFirstAvaliable} from '../rideslistactions';
import {DataService} from '../../../services/data.service';
import {fromArray} from 'rxjs/internal/observable/fromArray';
import {todayDateToString, weeksDates} from '../../../helpers/helpers';

@Injectable()
export class AllRidesEffects {

  private _token: string
  private _login: string
  private _count: number

  constructor(private _actions$: Actions, private _store: Store<AppState>,
              private _router: Router,
              private _message: MessagesService,
              private _data: DataService) {
    this._store.select(getAuthToken)
      .subscribe(
        token=>this._token = token
      )

    this._store.select(getAuthLogin)
      .subscribe(
        login=>this._login = login
      )

  }

  @Effect()
  getall$ = this._actions$.pipe(
    ofType(RidesListActions.GetAllRidesStart),
    tap((action)=>{
        this._store.dispatch(new GetAllRides())
        this._count = 0
    }
    ),
    switchMap(action=>{
      return fromArray(weeksDates(1)).pipe(
          mergeMap((date)=>{
            return this._data.getPools(date, this._token, this._login)
              .pipe(
                 switchMap((data)=>{
                   this._count++

                   let emit = []
                   emit.push(new GetOneDaySuccess(date, data))

                   if(this._count===6){
                    emit.push( new GetAllRidesSuccess())
                    }
                   return  emit
                 })
              )
          }
         ),
       )
    })
    )
}
