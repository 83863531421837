import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, switchMap, tap, map, concatMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs'
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState} from '../../index';
import {MessagesService} from '../../../services/messages.service';
import {GetOneDay, GetOneDaySuccess, RidesListActions, SetFirstAvaliable} from '../rideslistactions';
import {DataService} from '../../../services/data.service';

@Injectable()
export class GetOneDayStartEffect {


  constructor(private _actions$: Actions, private _store: Store<AppState>,
              private _router: Router,
              private _message: MessagesService,
              private _data: DataService) {

  }

  @Effect()
  getonestart$ = this._actions$.pipe(
    ofType(RidesListActions.GetOneDayStartEffect),
    tap((action)=>{
      console.log('Token', action)
        this._store.dispatch(new GetOneDay(action['date']))
      }
    ),
    switchMap(action=>{

      return this._data.getPools(action['date'], action['token'], action['login'])
        .pipe(
          concatMap(data=>[new GetOneDaySuccess(action['date'], data),
                  new SetFirstAvaliable()]),
              catchError((err)=>{
           console.log(err, action['token'])
           this._message.showDialog({
              title: 'getdataerror',
              message: '',
              //addInfo: err,
              yes: '',
              no: 'ok',
              result: 'ok'
            })
            return EMPTY
          })
        )
    })
   )
}
