import {Action} from '@ngrx/store';

export enum oneRideActions  {
  SetCurrentRide = "[Set Current Ride] for ride beginning",
  SetCurrentRideStart = "[Set the data] for set current ride",
  ChangeButtonStatus = "[Change Button Status] for indicate next turn",
  SetCurrentCustomerId = "[Customer id ] for right drawer",
  SetCurrentStatus = "[Current ride status] for right drawer",
  StartMission = "[Start mission ] for left drawer",
  ResetOneRide = "[Reset onde ride for] logout",
  ChangeAddressIdx = "[Change index for] share rides"
}

export class StartMission implements Action{
  readonly type = oneRideActions.StartMission
  constructor(){}
}

export class ChangeAddressIdx implements Action{
  readonly type = oneRideActions.ChangeAddressIdx
  constructor(public idx: number){}
}

export class ResetOneRide implements Action{
  readonly type = oneRideActions.ResetOneRide
  constructor(){}
}


export class SetCurrentRide implements Action{
  readonly type = oneRideActions.SetCurrentRide
  constructor(public rideId: string,
              public passengersIds: string[],
              public pickUpDate: string
              ){}
}

export class ChangeButtonStatus implements Action{
  readonly type = oneRideActions.ChangeButtonStatus
  constructor(public key: string,
              public status: string,
              ){}
}

export class SetCurrentStatus implements Action{
  readonly type = oneRideActions.SetCurrentStatus
  constructor(public id: string,
              public status: string,
  ){}
}

export class SetCurrentCustomerId implements Action{
  readonly type = oneRideActions.SetCurrentCustomerId
  constructor(public id: string ){}
}


export type oneRideTypes = StartMission | SetCurrentRide | ChangeButtonStatus |
                        SetCurrentStatus | SetCurrentCustomerId | ResetOneRide |
                        ChangeAddressIdx
