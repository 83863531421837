import { Pipe, PipeTransform } from '@angular/core';
import {sorting} from './helpers';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(data: any, field: {name: string, sort: boolean}[]): any {
   // console.log('DDDD', field, 'Data', data)
    if(field.length){
     for(let i=0;i<field.length;i++){
      data.sort((a,b)=>{
           let id = 0
           let result = 0

           while(result===0 && id<field.length){

              let a1 = a[field[id]['name']]
              let b1 = b[field[id]['name']]

             if(field[id]['name']==='date'){
                a1= new Date(a1).getTime()
                b1= new Date(b1).getTime()
              }

             if(field[id]['name']==='time'){
               a1= new Date("2016-01-01T"+a1+".00000Z").getTime()
               b1= new Date("2016-01-01T"+b1+".00000Z").getTime()
             }

             if(field[id]['name']==='phone'){
               a1= parseInt(a1)
               b1= parseInt(b1)
             }

              let ar = (a1>b1)? 1 : 0
              let br = (a1<b1)? 1 : 0
              result = (br - ar) * (field[id]['sort']? 1 : -1 )

               //console.log('Field', field[id],'A:',a1,' B:',b1,'Result', result, 'ID',id)

              id+=1
            }

           return result

         })
     }

      return [...data]

    }else{
      return data
    }

  }

}
