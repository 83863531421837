import { Component, OnInit, OnDestroy } from '@angular/core';
import {AppState, getAllDaysLoadedState, getAllRidesState, getLoadedState, getLoadingdState} from '../store';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {todayDateToString} from '../helpers/helpers';
import {Language, TranslationService} from 'angular-l10n';
import {GetAllRidesStart, GetOneDayStart} from '../store/rideslist/rideslistactions';

@Component({
  selector: 'app-rideslist',
  templateUrl: './rideslist.component.html',
  styleUrls: ['./rideslist.component.scss']
})
export class RideslistComponent implements OnInit, OnDestroy {

  @Language() lang: string

  public loading: boolean;
  public loadedAll: boolean;
  public loaded: any;
  public todayRides: any;
  //all rides
  public allRides: any;
  private subscription: Subscription =  new Subscription();

  constructor(private _store: Store<AppState>, public translate: TranslationService) { }

  //for universal view
  columnsNames: string[]
  //names of columns accordin to data array
  //data do dispaly universal view
  data: {}[]=[]
  windowTitle: string =''
  //uses by sort pip to proper order of order data
  sortByField: {name: string, sort: boolean }[] = [];
  //all rides loaded indicator
  today =  todayDateToString()

  //for week rider


  ngOnInit() {

    let sub$ = this._store.select(getLoadingdState)
      .subscribe(loading =>
        this.loading = loading
      )
    this.subscription.add(sub$)

    sub$ = this._store.select(getLoadedState)
      .subscribe(loaded =>
        this.loadedAll = loaded
      )
    this.subscription.add(sub$)


    //load all rides if didn't load eariler
    if(!this.loadedAll && !this.loading){
      this._store.dispatch(new GetAllRidesStart())
    }


    sub$ = this._store.select(getAllRidesState)
      .subscribe(
        data=>{
          this.allRides = {...data}
          this.todayRides = [...this.allRides[this.today]]
         }
      )
    this.subscription.add(sub$)

    sub$ = this._store.select(getAllDaysLoadedState)
      .subscribe(
        all=>{
            this.loaded = {...all}
        }
      )
    this.subscription.add(sub$)



    //initialize data for universal view
    this.columnsNames=['serviceStart', 'guest', 'from', 'to', 'driver', 'status']
    this.windowTitle = this.tr('list.Today') +' ' + todayDateToString()
    this.sortByField =[{name: 'serviceStart', sort: false}]

  }

  tr(source: string){
    return this.translate.translate(source)
  }

  startRide(e){

  }

  refreshDay(day: string){
    this._store.dispatch(new GetOneDayStart(day))
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }
}
