import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Language} from 'angular-l10n';

@Component({
  selector: 'app-directionmodal',
  templateUrl: './directionmodal.component.html',
  styleUrls: ['./directionmodal.component.scss']
})
export class DirectionmodalComponent implements OnInit, OnDestroy {

  @Language() lang: string
  choosen: string;
  point:{lat: number, lng: number, id: string};

  constructor(public dialogRef: MatDialogRef<DirectionmodalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log('Data dialog', this.data)

    this.choosen =  this.data.id

    let idx = this.data.drivingOrders.findIndex(item=>{
      return item.id===this.data.id
    }
    )

    console.log('idx initial', idx)

    this.point = {
      lat: this.data.drivingOrders[idx].startAddress.lat,
      lng: this.data.drivingOrders[idx].startAddress.lng,
      id: this.data.drivingOrders[idx].id
    }

  }

  choose(id: string){

    this.choosen = id

    let idx = this.data.drivingOrders.findIndex(item=>{
        return  item.id===id
    })

    this.point = {
      lat: this.data.drivingOrders[idx].startAddress.lat,
      lng: this.data.drivingOrders[idx].startAddress.lng,
      id: this.data.drivingOrders[idx].id
    }
  }

  ngOnDestroy(){}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
