import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {todayDateToString, weeksDates} from '../../helpers/helpers';
import {days} from '../../types/types';
import {Language} from 'angular-l10n';

@Component({
  selector: 'app-weekrides',
  templateUrl: './weekrides.component.html',
  styleUrls: ['./weekrides.component.scss']
})
export class WeekridesComponent implements OnInit, OnDestroy {

  @Language() lang: string

  public dates: {day: string, date: string}[] = [];

  private _data: any;
  private _loaded: any;

  @Input()
  set data(data: any){
    console.log('weerides', this.dates, this.data)
    this._data = {...data}
  }
  @Input()
  set loaded(val: any){
    this._loaded = {...val}
  }

  get loaded(){
    return {...this._loaded}
  }

  @Input()columnsNames:string[]
  @Input()sortByFiled: any;

  @Output()refresh: EventEmitter<string> = new EventEmitter<string>()

  constructor() { }

  get data(){
    return this._data
  }

  ngOnInit() {
      for(let i=1;i<7;i++){
        this.dates.push({day: days[(new Date(todayDateToString(i))).getDay()], date: todayDateToString(i) })
      }
  }

  ngOnDestroy(){}

  refreshDay(day: string){
    this.refresh.emit(day)
  }

}
