import * as geo from './geoactions'

export interface geostate {
  currLat: number;
  currLng: number;
  endLat: number;
  endLng: number;
  navigationLink: string;
  distance: number;
  time: number;
  trigger: boolean;
  openMapInNewTab: boolean;
}

export const initialGetoState: geostate = {
  currLat: 0,
  currLng: 0,
  endLat: 0,
  endLng: 0,
  distance: 0,
  navigationLink: null,
  time: 0,
  trigger: false,
  openMapInNewTab: true
}

export function georeducer(state: geostate =  initialGetoState, action: geo.GeoActionTypes):geostate {
  switch (action.type) {
    case geo.GeoActions.SetCurrentPosition:{
      return {...state, currLat: action.lat, currLng: action.lng}
    }
    case geo.GeoActions.SetEndPoint:
      return {...state, endLat: action.point.lat, endLng: action.point.lng, openMapInNewTab: action.point.openMapInNewTab }
    case geo.GeoActions.SetDirection:
    {
      return {...state, distance: action.distance, time: action.time}
    }
    case geo.GeoActions.CalculateDistance:
      return {...state,
              trigger: true,
              endLat: action.lat,
              endLng: action.lng,
              navigationLink: `https://maps.google.com/maps?ll=${state.currLat},${state.currLng}&z=6&t=m&hl=en-US&gl=US&mapclient=embed&saddr=${state.currLat},${state.currLng}&daddr=${state.endLat},${state.endLng}&dirflg=d`
            }
    case geo.GeoActions.SetCalculationTriger:
    {
      return {...state, trigger: action.tr}
    }
     case geo.GeoActions.ResetCalculationTriger:
    {
      return {...state, trigger: false}
    }
    case geo.GeoActions.SetOpenMapInNewTab:
    {
      return { ...state, openMapInNewTab: action.openMapInNewTab }
    }
   default:
    {
      return state
    }
  }
}

export const getCurrLat = (store: geostate)=>store.currLat
export const getCurrLng = (store: geostate)=>store.currLng
export const getTrigger = (store: geostate)=>store.trigger
export const directionLink = (store: geostate)=>store.navigationLink


