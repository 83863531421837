import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {
  AppState,
  getAppCurrentStatus,
  getCurrentPickUpDate,
  getCurrentRideIdState, getCurrentStatus, getOneRideIdState, getShownOrderId
} from '../store';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {appStatus} from '../store/ui/uireducer';
import {tap, filter} from 'rxjs/operators';
import {rideStatus} from '../types/types';
import {MessagesService} from '../services/messages.service';
import {copied} from './infobox/infobox.component';
import {OpenInfoWindow} from '../store/ui/uiactions';
import {todayDateToString} from '../helpers/helpers';
import { SetEndPoint } from '../store/georeducer/geoactions';


interface onebox {
  status: string;
  title: string;
  type: string;
  info: string;
  headLink?: string;
  iconLink?: string;
}

@Component({
  selector: 'app-infoboxes',
  templateUrl: './infoboxes.component.html',
  styleUrls: ['./infoboxes.component.scss']
})
export class InfoboxesComponent implements OnInit, OnDestroy {

  boxes: onebox[] = []
  passangerName: string
  phone: string
  pickuptime: string;
  from: string
  to: string

  private _date: string;
  private  _orderId: string;
  private  _rideId: string;
  private _data: any;
  private _statusAll: {};
  public status: string;

  //name and address for toolbar
  @Output()changed: EventEmitter<string>= new EventEmitter<string>()

  private _subscription: Subscription = new Subscription()

  constructor(private _store: Store<AppState>, private _message: MessagesService) {

    let sub$ = this._store.select(getAppCurrentStatus)
      .pipe(
        filter((data)=>data!==appStatus.DoingNothing),
        tap(()=>{
           //ride id
          sub$ = this._store.select(getCurrentRideIdState).subscribe(
            id=>this._rideId=id
          )
          this._subscription.add(sub$)

          //ride date
          sub$ = this._store.select(getCurrentPickUpDate).subscribe(
            date=>this._date=date
          )
           this._subscription.add(sub$)
            //day ride data
          sub$ = this._store.select(getOneRideIdState, {date: this._date, id: this._rideId})
            .subscribe(
              data=>this._data = {...data}
            )
           this._subscription.add(sub$)
           console.log('data', this._data)

          //extract one ride
          sub$ = this._store.select(getShownOrderId)
            .subscribe(
            id=>{

              this._orderId = id


              if(this._statusAll){
                  this.status = this._statusAll[this._orderId]
                }

                const info = {...this._data['drivingOrders'].find(item=>item['id']===this._orderId)}
                console.log('info info', info, this._orderId)

                if(Object.keys(info).length>0){
                  this.passangerName = info['passenger']['title'] + ' ' + info['passenger']['name']
                  this.pickuptime  = info['pickupTime']
                  this.phone = info['passenger']['telephone']
                  this.from = info['startAddress']['address']
                  this.to = info['targetAddress']['address']

                this.setTheBoxes()
                }

              }
             )
            this._subscription.add(sub$)

            sub$ = this._store.select(getCurrentStatus)
              .subscribe(status=>{
                console.log('apki status', status)
                this._statusAll = {...status}
                if(this._orderId){
                  this.status = this._statusAll[this._orderId]
                  console.log('this.status', this.status, this._orderId)
                }

                this.setTheBoxes()
              })

            }
          )
      ).subscribe()
          this._subscription.add(sub$)






   }

  ngOnDestroy(){
    this._subscription.unsubscribe()
  }

  ngOnInit() {
    //SetEndPoint when drive not started
    if (this.status === '') {
      const driveInfo = { ...this._data['drivingOrders'].find(item=>item['id']===this._orderId) }
      this._store.dispatch(new SetEndPoint({
        lat: driveInfo.startAddress.lat,
        lng: driveInfo.startAddress.lng,
        openMapInNewTab: false
      }))
    }
  }

  setTheBoxes(){
    this.boxes = []
    this.boxes.push({
      status: 'normal',
      title: 'guest',
      type: 'fahrgast',
      info: this.passangerName
    })

    this.boxes.push({
      status: 'normal',
      title: 'phone',
      type: 'phone',
      info: this.phone
    })

    this.boxes.push({
      status: 'normal',
      title: 'time',
      type: 'time',
      info: this.pickuptime
    })

    this.boxes.push({
      status: (this.status!==rideStatus.end && this.status && this.status!==rideStatus.start)? 'active' : 'normal',
      title: 'start',
      type: 'start',
      info: this.from
    })

    this.boxes.push({
      status: (this.status===rideStatus.start)? 'active' : 'normal',
      title: 'end',
      type: 'end',
      info: this.to
    })

  }

  callPhone(phone: string){
    console.log('open', phone)
    window.open("tel:" + phone);
  }

  copyTo(txt: copied){

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = txt.text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this._message.showDialog({
      title: txt.type +"hascopied",
      message: "",
      //addInfo: txt,
      yes:'',
      no:'ok',
      result: 'ok'
    })
  }

  showInfo(){
    this._store.dispatch(new OpenInfoWindow(todayDateToString(), this._rideId, this._orderId))
  }

}
