import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, getDailyCarList } from '../store';
import { Subscription } from 'rxjs';
import { Language } from 'angular-l10n';

@Component({
  selector: 'app-daily-car-list',
  templateUrl: './daily-car-list.component.html',
  styleUrls: ['./daily-car-list.component.scss']
})
export class DailyCarListComponent implements OnInit, OnDestroy {

  @Language() lang: string
  carListSubscription: Subscription;
  carList = [];

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.carListSubscription = this.store.select(getDailyCarList)
      .subscribe(res => {
        this.transformDataToCarList(res.pools);
      });
  }

  private transformDataToCarList(data: any) {
    let carNameList = []; //unique carName
    data.forEach(car => {
      if (!carNameList.includes(car.vehicle.name)) {
        carNameList.push(car.vehicle.name);
      }
    });

    //create list of drivers for each car
    carNameList.forEach(carName => {
      const car = data.filter(data => data.vehicle.name === carName);
      let carData = {
        name: carName,
        drivers: []
       }
      car.forEach(car => {
        if (car.driver.name != undefined && !carData.drivers.includes(`${car.driver.name} (${car.driver.telephone})`)) {
          carData.drivers.push(`${car.driver.name} (${car.driver.telephone})`);
        }
      })
      this.carList.push(carData);
    });
  }

  ngOnDestroy() {
    if (this.carListSubscription) {
      this.carListSubscription.unsubscribe();
    }
  }

}
