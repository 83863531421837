import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {AuthActions, AuthFail, AuthInProgress, AuthStart, AuthSucsess, LogOut} from '../authactions';
import {of} from 'rxjs'
import {catchError, switchMap, tap, map, filter, concat, concatMap, concatAll, mergeAll} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState} from '../../index';
import {MessagesService} from '../../../services/messages.service';
import {AuthService} from '../../../services/auth.service';
import {decodeJwtToken, todayDateToString} from '../../../helpers/helpers';
import {SetDispoServer} from '../../ui/uiactions';
import {GetOneDayStartEf} from '../../rideslist/rideslistactions';

@Injectable()
export class LoginEffects {


  constructor(private _actions$: Actions, private _store: Store<AppState>,
              private _router: Router, private _message: MessagesService, private _auth: AuthService) {}

  @Effect()
  loginProcess$ = this._actions$.pipe(
    ofType(AuthActions.AuthStart),
    tap((action)=>{
        this._store.dispatch(new AuthInProgress(action['login'], action['password']))
        this._store.dispatch(new SetDispoServer(action['server']))
       }
    ),
    switchMap((action)=> {
      return this._auth.getAuthToken(action['login'], action['password'], action['server']).pipe(
         switchMap(data=>{
            let decodedToken = decodeJwtToken(data['token'])
            //console.log('exp', decodedToken['exp'])

            //log out time
            // setTimeout(()=>{
            //   this._message.showDialog(
            //     {
            //       title: 'staylogin',
            //       message: 'staylogininfo',
            //       yes: "yes",
            //       no: "discard",
            //       result: 'yes'
            //     }
            //   ).subscribe(
            //     result=>{
            //       if(result==='yes'){
            //         this._store.dispatch(new AuthStart(action['login'], action['password'], action['server'], action['remember']))
            //       }else{
            //         this._store.dispatch(new LogOut())
            //         this._router.navigate(["/login"])
            //       }
            //     }
            //   )
            // }, decodedToken['exp'] - 5000);



           return [new AuthSucsess(data['token'],
                decodedToken['exp'],
                action['login'],
                action['password'],
                action['remember'],
              action['server']),
            // new GetAllRidesStart()
           ]
         }),
         tap(()=>{
           this._router.navigate(["/mainpage"])
         }),
         catchError((err =>{
           console.log('Jakis blad', err)
           this._message.showDialog({
             title: "loginerror",
             message: 'loginerrorinfo',
             yes: "",
             no: "Ok",
             result: "ok"
           }).subscribe(
             ()=>{
               return of(new AuthFail(err))
             }
           )
           return of(new AuthFail(err))
         }))
        )
      }
      ),
     switchMap((data)=>{
       this._store.dispatch(data)
       return [new GetOneDayStartEf(todayDateToString(), data['token'], data['login'])]
     })
   )
}
