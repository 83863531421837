import { Component, OnDestroy } from '@angular/core';
import {AppState, getInfoOpenedState, getAuthToken} from './store';
import {Store} from '@ngrx/store'
import {Subscription, interval, Observable, Subject} from 'rxjs';
import { LocaleService } from 'angular-l10n';
import { AuthService } from './services/auth.service';
import { AuthSucsess } from './store/auth/authactions';
import { decodeJwtToken } from './helpers/helpers';
import { take, tap, switchMap, share, retry, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy{
  title = 'tixi24new';

  infoWinodowOpened: boolean =  false

  private _subscription: Subscription = new Subscription()
  tokenSub: Subscription;
  private authObs: Observable<any>;
  private stopTokenRefresh = new Subject();

  constructor(
    private _store: Store<AppState>,
    public locale: LocaleService,
    private auth: AuthService
  ){
  //default language
    //this.locale.setDefaultLocale('en', 'GB')
    this.locale.setDefaultLocale('ge', 'CH');

  //for info window
    let sub$ = this._store.select(getInfoOpenedState)
    .subscribe(
      open=>{
        this.infoWinodowOpened = open
      }
    )
    this._subscription.add(sub$)

    //refresh token when expires
    this.tokenSub = this._store.select(getAuthToken).subscribe(
      token => {
        if (token) {
          let decodedToken = decodeJwtToken(token);
          const expireTimeInMiliseconds = new Date(decodedToken['exp'] * 1000).getTime() - new Date().getTime();

          this.authObs = interval(expireTimeInMiliseconds).pipe(
            switchMap(() => this.auth.getAuthToken(localStorage.getItem('car'), localStorage.getItem('pass'), localStorage.getItem('server'))),
            take(1),
            retry(),
            share(),
            takeUntil(this.stopTokenRefresh)
          )

          this.authObs.pipe(
            tap(data => {
                  this._store.dispatch(new AuthSucsess(
                    data['token'],
                    decodedToken['exp'],
                    localStorage.getItem('car'),
                    localStorage.getItem('pass'),
                    localStorage.getItem('remember') == "true",
                    localStorage.getItem('server')
                  ))
            })
          ).subscribe();

        } else {
          this.stopTokenRefresh.next();
        }
      }
    )
  }

  ngOnDestroy(){
    this._subscription.unsubscribe();
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }
    this.stopTokenRefresh.next();
  }

}
