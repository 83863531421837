import {Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {Language} from 'angular-l10n';

export interface copied {
  text: string;
  type: string
}

@Component({
  selector: 'app-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss']
})
export class InfoboxComponent implements OnInit, OnDestroy {

   @Language() lang: string;

   //@ContentChild() element: any;
   @ViewChild('content') elem: ElementRef
   @Output() copyText: EventEmitter<copied> = new EventEmitter<copied>()
   @Output() callTo: EventEmitter<string> = new EventEmitter<string>()
   @Output() info: EventEmitter<boolean> = new EventEmitter<boolean>()

   @Input() status: string;
   @Input() title: string;
   @Input() type: string;
   @Input() headLink: string;
   @Input() iconLink: string;
   iconName: string;
   phone: string;

  constructor() {


  }

  ngOnInit() {

    switch (this.type) {
      case 'fahrgast':
          this.iconName = "person"
          break;
      case 'phone':
          this.iconName = "phone"
          break;
      case 'start':
        this.iconName = "arrow_forward"
        break;
      case 'end':
        this.iconName = "arrow_backward"
        break;
    }

  }

  callPhone(){
    this.callTo.emit(this.elem.nativeElement.innerText)
  }

  showInfo(){
    this.info.emit(true)
  }

  copyTo(type: string){
    console.log(this.elem.nativeElement.innerText)
    this.copyText.emit({text: this.elem.nativeElement.innerText, type: type})
   }

  ngOnDestroy(){

  }

}
