import {oneday, onedayloaded} from '../store/rideslist/rideslistreducer';

export const transformDriverOrders=(row: any)=>{
  let rides = []

  for(let id in row['drivingOrders']){

    let drivingOrders = {...row['drivingOrders'][id]}

    drivingOrders = {...drivingOrders['passenger'], ...drivingOrders}
    drivingOrders['startAddress'] = drivingOrders['startAddress']['address']
    drivingOrders['targetAddress'] = drivingOrders['targetAddress']['address']
    drivingOrders['name'] = drivingOrders['title'] + ' ' + drivingOrders['name']
    drivingOrders['driver'] = drivingOrders['driver']['name'] + ' ' + drivingOrders['driver']['telephone']

    delete drivingOrders['title']
    delete drivingOrders['passenger']
    delete drivingOrders['pickupDate']
    delete drivingOrders['monthlyBill']
    delete drivingOrders['id']
    delete drivingOrders['insurance']

    rides.push(drivingOrders)
  }

  return rides
}

export const initialDailyRides=()=>{
  let tmp: oneday={};
  for(let i:number = 0;i<7;i++) {
    tmp[todayDateToString(i)] = []
  }
  return tmp
}

export const initialDayLoaded=(loaded: boolean)=>{
  let tmp: onedayloaded={};
  for(let i:number = 0;i<7;i++) {
    tmp[todayDateToString(i)] = loaded
  }
  return tmp
}

export const todayDateToString=(todayPlus: number = 0 ): string =>{
  const today = new Date()
  today.setDate(today.getDate()+ todayPlus)
  return today.toISOString().slice(0,10);
}


export const weeksDates = (i: number = 0):string[] =>{
  let dates: string[] = []

  for(i;i<7;i++) {
    dates.push(todayDateToString(i))
  }
  return dates
}

export const sorting = (field: string, back: boolean) =>{

  let reverse: number = back? -1 : 1

  return (a, b)=>{
    a = a[field]
    b = b[field]
    let a1: number = (a>b) ? 1 : 0
    let a2: number = (b>a) ? 1: 0
    return reverse * (a1 - a2);
  }

}

export const decodeJwtToken = (token: string)=>{
  let b64 = token.split('.')[1]
  let base64 = decodeURIComponent(atob(b64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(base64)
}

export const nextRide=(current: {}, data: {}[])=>{

  let indexes = []
  const curr =  convertT(current['serviceStart'])

  for (let i=0;i<data.length;i++){
   if(curr<convertT(data[i]['serviceStart'])){
      indexes.push(i)
    }
  }

  let min = indexes[0]

  for(let i=1;i<indexes.length;i++){
    const tmp2 = convertT(data[indexes[i]]['serviceStart'])
    const tmp1 = convertT(data[min]['serviceStart'])
   if(tmp2<tmp1){
     min = indexes[i]
   }

  }
    if (min!==undefined){
      return data[min]['id']
    }else{
      return null
    }
}

export function convertT(time: string){
  let tmp = time.split(':')
  let result = 0
  let x=tmp.length-1

  while(x>-1){
    switch (x) {
      case 1:
      {
        result = result + parseInt(tmp[x])*60
        break;
      }
      case 0 :
      {
        result = result + parseInt(tmp[x])*60*60
        break;
      }
    }
    x-=1;
  }

  return result
}

