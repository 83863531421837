import * as ui from './uiactions';

export enum appStatus {
  //when the app is clear (no chosen ride)
  DoingNothing = "[App is doing] nothing",
  //after chosen the ride
  WaitingFor = "[App is waiting] for start to cliten",
  //during the ride mode
  OnRide = '[App is on ride] mode',
}

export interface uistate {
  server: string
  showRightDrawer: boolean;
  showLeftDrawer: boolean;
  infoOpened: boolean;
  infoDay: string;
  infoId: string;
  orderId: string;
  appStatus: string;
}

export const uiStateInitial = {
  server: '',
  showLeftDrawer: false,
  showRightDrawer: false,
  infoOpened: false,
  infoDay: null,
  infoId: null,
  orderId: null,
  appStatus: appStatus.DoingNothing
}

export function uireducer(state: uistate =  uiStateInitial, action: ui.UiActionsTypes) {
  switch (action.type) {
    case ui.UiActions.CloseRightDrawer:
    {
      return {...state, showRightDrawer: false}
    }
    case ui.UiActions.SetAppStatus:
    {
      return {...state, appStatus: action.status}
      break;
    }
    case ui.UiActions.SetDispoServer:
    {
      return {...state, server: action.server}

      break;
    }
    case ui.UiActions.ToggleRightDrawer:
    {
      return {...state, showRightDrawer: !state.showRightDrawer}
      break;
    }
    case ui.UiActions.ToggleLeftDrawer:
    {
      return {...state, showLeftDrawer: !state.showLeftDrawer}
      break;
    }
    case ui.UiActions.OpenInfoWindow:
    {
      return {...state, infoOpened: true, infoDay: action.date, infoId: action.rideId, orderId: action.orderId}
      break;
    }
    case ui.UiActions.CloseInfoWindow:
    {
      return {...state, infoOpened: false, infoDay: null, infoId: null }
    }
    case ui.UiActions.ChengeShownOrderId:
    {
      console.log('orderId', state, action)
      return {...state, orderId: action.id}
    }
    default:
    {
      return state
     }
  }
}

export const getAppStatus = (state: uistate) =>state.appStatus
export const getInfoId = (state: uistate) =>state.infoId
export const getInfoDay = (state: uistate) =>state.infoDay
export const getOpenInfo = (state: uistate)=>state.infoOpened
export const getLeftDrawerState = (state: uistate)=>state.showLeftDrawer
export const getRightDrawerState = (state: uistate)=>state.showRightDrawer
export const getServer = (state: uistate)=>state.server
export const getOrderId = (state: uistate)=>state.orderId
