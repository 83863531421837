import {Action} from '@ngrx/store';

export enum RidesListActions {
  GetAllRidesStart =  '[Get all Rides] for rideslist component',
  GetAllRides = '[Get all rides ] to indicate start',
  GetOneDayStart = '[Get one day] for refres function',
  GetOneDay = '[Get one day] for indicate in progress',
  AllRidesSuccess = '[Get all rides succes] for indicate finish',
  OneDaySuccess = '[Day success] for store and indicate finis',
  AllRidesFail = '[All rides fail] for indicate error',
  OneDayFail = '[One day fail] fro indicate error',
  ResetRidesState = '[Reset rides state] for log out',
  CalculateNextRide = '[Calculate next ride] for end action button',
  SetSharedRide = '[Set shared for shared rides] main page ',
  SetFirstAvaliable = '[Frist avaliable] after app loaded',
  SetCurrentRideEffect = '[Set the ride] for',
  GetOneDayStartEffect = '[get data for start] start effect',
  SetDailyCarList = '[set daily car list] for view',
  GetDailyCarList = '[get daily car list] for view'
}

export class GetOneDayStartEf implements Action {
  readonly type = RidesListActions.GetOneDayStartEffect
  constructor(public date: string, public token: string, public login: string){}
}


export class SetCurrentRideEf implements Action {
  readonly type = RidesListActions.SetCurrentRideEffect
  constructor(public id: string, public date: string){}
}


export class SetSharedRide implements Action {
  readonly type = RidesListActions.SetSharedRide
  constructor(public rideId: string, public date: string){}
}

export class SetFirstAvaliable implements Action {
  readonly type = RidesListActions.SetFirstAvaliable
  constructor(){}
}


export class CalculateNextRide implements Action {
  readonly type = RidesListActions.CalculateNextRide
  constructor(public currRideId: string, public date: string){}
}


export class ResetRidesState implements Action {
  readonly type = RidesListActions.ResetRidesState
  constructor(){}
}

export class GetAllRidesStart implements Action {
  readonly type = RidesListActions.GetAllRidesStart
  constructor(){}
}

export class GetAllRides implements Action {
  readonly type = RidesListActions.GetAllRides
  constructor(){}
}

export class GetOneDay implements Action {
  readonly type = RidesListActions.GetOneDay
  constructor(public date: string){}
}

export class GetOneDaySuccess implements Action {
  readonly type = RidesListActions.OneDaySuccess
  constructor(public date: string, public data: any){}
}

export class GetOneDayFail implements Action {
  readonly type = RidesListActions.OneDayFail
  constructor(public date: string){}
}


export class GetOneDayStart implements Action {
  readonly type = RidesListActions.GetOneDayStart
  constructor(public date: string){}
}

export class GetAllRidesSuccess implements Action {
  readonly type = RidesListActions.AllRidesSuccess
  constructor(){}
}

export class GetAllRidesFail implements Action {
  readonly type = RidesListActions.AllRidesFail
  constructor(){}
}

export class SetDailyCarList implements Action {
  readonly type = RidesListActions.SetDailyCarList
  constructor(public data: any){}
}

export class GetDailyCarList implements Action {
  readonly type = RidesListActions.GetDailyCarList
  constructor(){}
}

export type RideListActionTypes = GetAllRidesSuccess | GetAllRidesStart |
                                  GetOneDay | GetOneDayStart | GetAllRides |
                                  GetOneDaySuccess | GetOneDayFail |
                                  GetAllRidesFail | ResetRidesState | CalculateNextRide |
                                  SetSharedRide | SetFirstAvaliable | SetCurrentRideEf | GetOneDayStartEf |
                                  SetDailyCarList | GetDailyCarList

