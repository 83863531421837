
export interface buttonStatus {
  info: string;
  status: string;
}

export const buttonStatusTypes = {
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
  INPROGRESS: 'INPROGRESS',
  NOTDONEYET: 'NOTDONEYET',
  WARN: 'WARN',
  HIDEN: 'HIDEN'
}

export interface appMessage {
  //all strings are keys from language file
  type?: string; //type of message as Error, Info, etc.
  title: string;  //modal title
  message: string; //body of modal
  addInfo?: string //add info
  yes: string; //yes button text if is empty doesn't show
  no: string; //no button text
  result: string; //returned resuld for take action after close
}

//for main page and infoboxes

export interface passengerInfo {
  name: string;
  phone: string;
  from: string;
  to: string
}

//App state for whole process
export enum currentAppStateEnum {
  DoingNothing = 'Waiting for next ride',
  OnTheWayToClient = 'After set the current location, send SMS, on the way',
  AprochedClientStartAddress = 'Pick up point client start address',
  OnTheWayToDestination = 'Ride with client to destination point'
}

//Driver set the start point then starts to drive to client start address
//app calculate possibly arrival time and send sms
//deiver riches client pick up point then
//ride starts
//approches client destination point

//for take all rides for week

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const dridingOrderStatus = {
  checkin: 'checkin',
  depart: 'depart',
  arrive: 'arrive',
  cancel: 'cancel'
}

export enum rideStatus {
  toclient = "Ride to client",
  sms = "Sending sms",
  onclient = "Pick up client",
  start = "Start with client",
  end = "end ride"
}

export enum missionStatus {
  checkedin = 1,
  departed,
  arrived,
  canceled,
  noshow
}


export enum orderStatus {
  checkedin = 1,
  departed,
  completed,
  canceled,
  noshow
}
