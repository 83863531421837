import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AppState, getAuthToken} from '../store';
import { AuthStart } from '../store/auth/authactions';



@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate{

  token: string

  constructor(private _store: Store<AppState>, private _router: Router) {
    this._store.select(getAuthToken)
      .subscribe(
        (token)=>{
          this.token = token !== null ? token : localStorage.getItem('token');

          if (token === null && this.token !== null) {
            this._store.dispatch(new AuthStart(
              localStorage.getItem('car'),
              localStorage.getItem('pass'),
              localStorage.getItem('server'),
              localStorage.getItem('remember') == "true"
            ))
          }
        })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if (this.token!==null){
      return true;
     }

    this._router.navigate(['/login']);
    return false;
  }

}
