import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {appMessage} from '../../types/types';
import {Language} from 'angular-l10n';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy{

  constructor(public dialogRef: MatDialogRef<ModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: appMessage) { }

  @Language() lang: string

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(){}

  ngOnDestroy(){ }
}
