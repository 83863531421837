import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {restconfig, servers} from '../config/config';
import {map, filter, switchMap, tap, toArray, mergeMap, concatMap, finalize} from 'rxjs/operators';
import {iif,  throwError} from 'rxjs';
import {fromArray} from 'rxjs/internal/observable/fromArray';
import {AppState, getServerName} from '../store';
import {Store} from '@ngrx/store'
import { SetDailyCarList } from '../store/rideslist/rideslistactions';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _server: string

  constructor(private _http: HttpClient, private _store: Store<AppState>) {

    this._store.select(getServerName)
      .subscribe(
        serv => this._server = servers[serv]
      )

  }

  getPools(date: string, token: string, login: string){
   return this._http.get(this._server
      + restconfig.getpools
      + `?nounce=1234789&day=`
      + date, this.httpOptions(token))
      .pipe(
        tap((data)=> {
          console.log('date', date, 'token' ,token, 'login', login, 'data', data);
          this.addDailyCarListToStore(data);
        }),
        switchMap(data=>
         iif(()=>data['status']!==200,
            throwError(data),
            fromArray(data['pools'] )
              .pipe(
                  filter(data=>data['vehicle']['name']===login),
                  concatMap((data)=>this.getMission(data['id'], token, data['driver'])),
                  mergeMap(data=>data),
                  toArray()
              ))
        )
       )

  }

  httpOptions(token: string){
    const headerDict = {
      'Authorization': 'Bearer ' + token
    }
    const httpOptions = {
      headers:  new HttpHeaders(headerDict)
    }
    return httpOptions
  }

  getMission(missionId: string, token: string, driver: any){
    return this._http.get(
      this._server
      + restconfig.missions + `?nounce=1234789&poolId=`
      + missionId, this.httpOptions(token)).pipe(
      map(data=>{
        let dataToReturn = data['missions'];
        dataToReturn.forEach(mission => { //add driver object to mission
          mission['driver'] = driver['name']; // to display in view /rides column driver
          mission['drivingOrders'].forEach(drive => { // to display in rides details
            drive['driver'] = driver;
          });
        });
        try{
          let pickupTimes = []
          let sortedDrivingOrders = []
          if(dataToReturn[0]['drivingOrders'].length > 1){
            for (let i=0; i<dataToReturn[0]['drivingOrders'].length; i++){
              pickupTimes[i] = dataToReturn[0]['drivingOrders'][i]['pickupTime']
            }
            pickupTimes = pickupTimes.sort()
            for (let j=0; j<pickupTimes.length; j++){
              for (let i=0; i<dataToReturn[0]['drivingOrders'].length; i++){
                if (pickupTimes[j]==dataToReturn[0]['drivingOrders'][i]['pickupTime']){
                  sortedDrivingOrders[j]=dataToReturn[0]['drivingOrders'][i]
                }
              }
            }
            dataToReturn[0]['drivingOrders'] = sortedDrivingOrders
          }
        }
        catch(err){}

        return dataToReturn;
      })
    )
  }

  private addDailyCarListToStore(data: any) {
    this._store.dispatch(new SetDailyCarList(data));
  }


}
