import * as rl from './rideslistactions'
import {convertT, initialDailyRides, initialDayLoaded} from '../../helpers/helpers';
import {JSONP_ERR_WRONG_METHOD} from '@angular/common/http/src/jsonp';

export interface oneday {
  [date: string]: {}[];
}

export interface  onedayloaded {
  [date: string]: boolean;
}

export interface ridesliststate {
  dailyrides: oneday
  dayloaded: onedayloaded;
  current: {};
  loaded: boolean;
  loading: boolean;
  nextRideId: string;
  dailyCarList: any;
}

export const rideListStateInitial = {
  dailyrides: {...initialDailyRides()},
  dayloaded: {...initialDayLoaded(false)},
  current: {},
  loaded: false,
  loading: false,
  nextRideId: null,
  dailyCarList: null
}


export function rideslistreducer(state: ridesliststate = rideListStateInitial,
                                 action: rl.RideListActionTypes)
{
  switch (action.type) {
    case rl.RidesListActions.SetSharedRide:
    {
      let idx = state.dailyrides[action.date].findIndex(item=>
        item['id']===action.rideId
      )
      console.log('idx dla ride', idx, state.dailyrides[action.date][idx])
       //reference to ride
      sharedRideTransform(state.dailyrides[action.date][idx])
      const tmp = {...state.dailyrides[action.date][idx]}

      return {...state, current: tmp, dailyrides: {...state.dailyrides, [action.date]:[...state.dailyrides[action.date]]}}
    }
    case rl.RidesListActions.AllRidesSuccess:
    {
      return {...state, loaded: true, loading: false}
    }
    case rl.RidesListActions.GetOneDay:
    {
      return {...state, dayloaded: {...state.dayloaded, [action.date]: false}}
    }

    case rl.RidesListActions.OneDaySuccess:
    {
      transformMissions(action.data)
      deletePitstop(action.data)
      return {...state,
                dailyrides:{...state.dailyrides, [action.date]: [...action.data]},
                dayloaded: {...state.dayloaded, [action.date]: true}
            }
    }
    case rl.RidesListActions.ResetRidesState:
    {
      return rideListStateInitial
    }
    case rl.RidesListActions.GetAllRides:
      {
        return {...state, loaded: false, loading: true}
      }
    case rl.RidesListActions.CalculateNextRide:
    {
      return {...state, nextRideId: nextRide(action.currRideId, state.dailyrides[action.date])}
    }
    case rl.RidesListActions.SetDailyCarList:
    {
      return { ...state, dailyCarList: action.data }
    }
    default:
    {
      return state
    }
  }

}

export const getAllRides = (state: ridesliststate) => state.dailyrides;
export const getDayLoaded = (state: ridesliststate)=> state.dayloaded;
export const getAllLoaded = (state: ridesliststate) => state.loaded;
export const getLoading =  (state: ridesliststate)=> state.loading;
export const nextRideId = (state: ridesliststate)=> state.nextRideId;
export const currentRide = (state: ridesliststate)=> state.current;
export const getDailyCarList = (state: ridesliststate) => state.dailyCarList;

const transformMissions=(data:{}[])=>{
  //console.log('Transform', data)
  let von: string = ""
  let nach: string = ""
  let fahrgast: string = ""

  for(let id in data){
    von = ""
    nach=""
    fahrgast=""
    //console.log(data[id])
    for(let ido in data[id]['drivingOrders']){
      von += `<br>` +data[id]['drivingOrders'][ido]['startAddress']['address']
      nach += `<br>` +data[id]['drivingOrders'][ido]['targetAddress']['address']
      fahrgast += `<br>` +data[id]['drivingOrders'][ido]['passenger']['name']

    }

    data[id]['from'] = von
    data[id]['to'] = nach
    data[id]['guest'] = fahrgast
  }

}

export const deletePitstop=(data: any)=>{
  for(let i=0;i<data.length;i++){
   if(data[i]['direction']==='pitstop')
    {
      data.splice(i, 1)
    }
  }
}


//find the next ride more difficult version (could sort rides be time an take next :-)
export const nextRide=(currentId: string, data: {}[])=>{

  let indexes = []
  const current = data.find(item=>item['id']===currentId)
  const curr =  convertT(current['serviceStart'])

  for (let i=0;i<data.length;i++){
    if(curr<convertT(data[i]['serviceStart']) && data[i]['status']!==2){
      indexes.push(i)
    }
  }

  let min = indexes[0]

  for(let i=1;i<indexes.length;i++){
    const tmp2 = convertT(data[indexes[i]]['serviceStart'])
    const tmp1 = convertT(data[min]['serviceStart'])
    if(tmp2<tmp1){
      min = indexes[i]
    }

  }
  if (min!==undefined){
    return data[min]['id']
  }else{
    return null
  }
}

export const sharedRideTransform=(ride: any)=>{

  let tmp = []

  if(ride['drivingOrders'].length>1){
  tmp = JSON.parse(ride['memo'])
  console.log('memo', tmp)
  let ix: number =-1

    for (let j=0;j<tmp.length; j++){
      for (let i=0;i<ride['drivingOrders'].length; i++){



        let geocode = tmp[j]['geocode']
        geocode = geocode.split(",")
        let lat = geocode[0]
        let lng = geocode[1]

        if (tmp[j]['address_id'] == ride['drivingOrders'][i]['startAddress']['id']){
          tmp[j]['type'] = 'startAddress'
          tmp[j]['lat'] = lat
          tmp[j]['lng'] =  lng
          tmp[j]['orderId'] = ride['drivingOrders'][i]['id']
        }
        if (tmp[j]['address_id'] == ride['drivingOrders'][i]['targetAddress']['id']){
          tmp[j]['type'] = 'targetAddress'
          tmp[j]['lat'] = lat
          tmp[j]['lng'] =  lng
          tmp[j]['orderId'] = ride['drivingOrders'][i]['id']
        }
      }


    }

    /*
    for (let i=0;i<tmp.length; i++){
      let geocode = tmp[i]['geocode']
      geocode = geocode.split(",")
      let lat = geocode[0]
      let lng = geocode[1]

      if(i==0){
        tmp[i]['type'] = 'startAddress'
        tmp[i]['lat'] = lat
        tmp[i]['lng'] =  lng
        tmp[i]['orderId'] = ride['drivingOrders'][0]['id']
      } else if(i==1) {
        tmp[i]['type'] = 'targetAddress'
        tmp[i]['lat'] = lat
        tmp[i]['lng'] = lng
        tmp[i]['orderId'] = ride['drivingOrders'][0]['id']
      } else if(i==2) {
        tmp[i]['type'] = 'startAddress'
        tmp[i]['lat'] = lat
        tmp[i]['lng'] =  lng
        tmp[i]['orderId'] = ride['drivingOrders'][1]['id']
      } else {
        tmp[i]['type'] = 'targetAddress'
        tmp[i]['lat'] = lat
        tmp[i]['lng'] =  lng
        tmp[i]['orderId'] = ride['drivingOrders'][1]['id']
      }

    }
*/


    /*
  for (let i=0;i<ride['drivingOrders'].length; i++){

    ix = tmp.findIndex(item=>item['id'],ride['drivingOrders'][i]['startAddress']['id'])
    console.log(i)
    console.log(ride)
    console.log(ride['drivingOrders'])




    if(ix>-1){
      tmp[i]['type'] = 'startAddress'
      tmp[i]['orderId'] = ride['drivingOrders'][i]['id']
      tmp[i]['lat'] = ride['drivingOrders'][i]['startAddress']['lat']
      tmp[i]['lng'] =  ride['drivingOrders'][i]['startAddress']['lng']
    }

    ix = tmp.findIndex(item=>item['id']===ride['drivingOrders'][i]['targetAddress']['id'])
    if(ix>-1){
      tmp[i]['type'] = 'targetAddress'
      tmp[i]['orderId'] = ride['drivingOrders'][i]['id']
      tmp[i]['lat'] = ride['drivingOrders'][i]['targetAddress']['lat']
      tmp[i]['lng'] =  ride['drivingOrders'][i]['targetAddress']['lng']
    }


  }
*/

 }else{

    tmp.push(
     {
       type: 'startAddress',
       orderId: ride['drivingOrders'][0]['id'],
       lat: ride['drivingOrders'][0]['startAddress']['lat'],
       lng: ride['drivingOrders'][0]['startAddress']['lng'],
     }
   )

    tmp.push(
      {
        type: 'targetAddress',
        orderId: ride['drivingOrders'][0]['id'],
        lat: ride['drivingOrders'][0]['targetAddress']['lat'],
        lng: ride['drivingOrders'][0]['targetAddress']['lng'],
       }
    )

  }

  console.log('Shared ride', tmp)
  ride['shared'] = tmp

}

export const firstAvaliable=(daylirides: any[])=>{
  let indexes = []
  //only avalible

  console.log('dailyl', daylirides)

  for(let i=0;i<daylirides.length;i++){

    if(daylirides[i]['status']===0 && daylirides[i]['direction']!=="pitstop"){
      indexes.push(i)
    }

  }
 console.log('indexes', indexes)
  //find min
  let min = indexes[0]

  for(let i=1;i<indexes.length;i++){
    const tmp2 = convertT(daylirides[indexes[i]]['serviceStart'])
    const tmp1 = convertT(daylirides[min]['serviceStart'])
    if(tmp2<tmp1){
      min = indexes[i]
    }
  }
  //console.log('pierwszy', daylirides[min]['id'])
  if (min!==undefined){
    return daylirides[min]['id']
  }else{
    return null
  }

}
